import {OBTAIN_DEVICE_ALARM, OBTAIN_DEVICE_ALARM_4_USER} from '../constant/action-type'

const initialState = {
    alarm4UserCount: 0,
    size: 15,
    page: 0,
    upPage: 0,
    nextPage: 1,
    totalCount: 50,
    totalPage: 4,
    firstResult: 0,
    lastResult: 15,
    dataList: [{
        mac: "98D3232D63",
        warningMessage: "设备滤网使用到期",
        customerName: "市人民医院",
        time: "2020-04-16-22:12:00"
    }]
}

function alarmReducer(state = initialState, action) {
    if (action.type === OBTAIN_DEVICE_ALARM) {
        return Object.assign({}, state, action.payload);
    }
    if (action.type === OBTAIN_DEVICE_ALARM_4_USER) {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default alarmReducer;