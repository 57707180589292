import {OBTAIN_LOCATION} from "../constant/action-type";

const initialState = {
    city_id: "",
    city_name: "",
    city_code: "",
    district: "",
    province: ""
}


function locationReducer(state = initialState, action) {
    if (action.type == OBTAIN_LOCATION) {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default locationReducer;