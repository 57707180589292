import axios from './request';

let base = "http://47.103.193.179:9090/Juguang"

function obtainSysLog(page, size) {
    let url = base + "/systemLog?";
    let params = new URLSearchParams();
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + params.toString();
    return axios.get(url).then(response => {
        return response.data;
    })
}

function obtainSysLogByMac(mac, page, size) {
    let url = base + "/systemLog/byMac/" + mac + "?";
    let params = new URLSearchParams();
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + params.toString();
    return axios.get(url).then(response => {
        return response.data;
    })
}

export const log_service = {
    obtainSysLog, obtainSysLogByMac
}
