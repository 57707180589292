//填写登录用户名
export const FILL_USERNAME = "FILL_USERNAME";

//填写登录密码
export const FILL_PASSWORD = "FILL_PASSWORD";

//生成验证码
export const GEN_CAPTCHA = "GEN_CAPTCHA";

//填写验证码
export const FILL_CODE = "FILL_CODE";

//显示提示
export const SHOW_ALERT = "SHOW_ALERT";

//隐藏提示
export const HIDE_ALERT = "HIDE_ALERT";

//生成提示信息
export const GEN_ALERT_MSG = "GEN_ALERT_MSG";

//修改时间
export const UPDATE_TIME = "UPDATE_TIME";

//修改日期
export const UPDATE_DATE = "UPDATE_DATE";

//更新在线率
export const UPDATE_ONLINE_RATE = "UPDATE_ONLINE_RATE";

//更新总设备数
export const UPDATE_TOTAL_COUNT = "UPDATE_TOTAL_COUNT";

//医院账号信息
export const OBTAIN_HOSPITAL_NAME = "OBTAIN_HOSPITAL_NAME";

//供应商信息
export const OBTAIN_CORP_INFO = "OBTAIN_CORP_INFO";

//账号登录用户名、密码信息

export const SAVE_CREDENTIAL = "SAVE_CREDENTIAL";

//城市空气数据
export const OBTAIN_CITY_WEATHER = "OBTAIN_CITY_WEATHER";

//设备告警信息
export const OBTAIN_DEVICE_ALARM = "OBTAIN_DEVICE_ALARM";

//当前用户的设备告警信息
export const OBTAIN_DEVICE_ALARM_4_USER = "OBTAIN_DEVICE_ALARM_4_USER";

//获取当前的用户列表
export const OBTAIN_USERS = "OBTAIN_USERS";

//获取当前的设备
export const OBTAIN_DEVICE = "OBTAIN_DEVICE";

//获取客户信息
export const OBTAIN_CUSTOMER = "OBTAIN_CUSTOMER";

//获取系统日志
export const OBTAIN_SYS_LOG = "OBTAIN_SYS_LOG";

//获取地理位置信息
export const OBTAIN_LOCATION = "OBTAIN_LOCATION";

//获取当前室外的空气数据
export const OBTAIN_CURRENT_AIRQUALITY = "OBTAIN_CURRENT_AIRQUALITY";

//获取滤芯使用状态
export const OBTAIN_SURPLUS_STATUS = "OBTAIN_SURPLUS_STATUS";

//获取设备运行状态
export const OBTAIN_RUNNING_STATUS = "OBTAIN_RUNNING_STATUS";

//获取传感器状态
export const OBTAIN_SENSOR_STATUS = "OBTAIN_SENSOR_STATUS";

//获取用户列表
export const OBTAIN_USER_LIST = "OBTAIN_USER_LIST";

//获取定时设置
export const OBTAIN_TIMING_CONFIG = "OBTAIN_TIMING_CONFIG";

//获取程控设置
export const OBTAIN_CONTROL_CONFIG = "OBTAIN_CONTROL_CONFIG";

//获取杀菌因子状态
export const OBTAIN_BACTERRICIDAL_STATUS = "OBTAIN_BACTERRICIDAL_STATUS";