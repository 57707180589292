import React from 'react'
import {Card} from 'react-bootstrap'
import {connect} from 'react-redux'
import {machine_service} from "../../service/machine";

import {refreshSensorStatus} from '../../action'

function mapStateToProps(state) {
    return {
        pm2_5: state.statusReducer.sensor_pm2_5,
        co2: state.statusReducer.sensor_co2,
        temp: state.statusReducer.sensor_temp,
        temp_out: state.statusReducer.sensor_temp_out,
        tvoc: state.statusReducer.sensor_tvoc,
        uv_strength: state.statusReducer.sensor_uv_strength,
        hcho: state.statusReducer.sensor_hcho,
        ir: state.statusReducer.sensor_ir,
        humidity: state.statusReducer.sensor_humidity
    }
}

const mapDispatchToProps = {
    refreshSensorStatus
}

class SensorStatusComponent extends React.Component {

    constructor(props) {
        super(props);
        this.obtain_sensor_status.bind(this);
    }

    componentDidMount() {
        if (this.props.mac !== undefined && this.props.mac !== "") {
            this.obtain_sensor_status(this.props.mac)
        }
    }

    // componentWillReceiveProps(next) {
    //     if (next.mac !== "" && next.mac != this.props.mac) {
    //         this.obtain_sensor_status(next.mac)
    //     }
    // }

    obtain_sensor_status(mac) {
        machine_service.obtainSensorStatus(mac).then(response => {
            response = response.data;
            if (response === null) return;
            this.props.refreshSensorStatus({
                sensor_pm2_5: response.pm2_5,
                sensor_co2: response.co2,
                sensor_temp: response.temp,
                sensor_temp_out: response.temtemp_out,
                sensor_tvoc: response.tvoc,
                sensor_uv_strength: response.uvStrength,
                sensor_hcho: response.hcho,
                sensor_ir: response.irsensor,
                sensor_humidity: response.humidity
            })
        })
    }

    render() {
        return (
            <Card className="detail-card">
                <Card.Header>监控数据</Card.Header>
                <Card.Body>
                    {this.props.uv_enabled === true &&
                    <div className="detail-circle-panel">
                        <div className="detail-circle">
                            <div className="circle-top">
                                紫外强度
                            </div>
                            <div className="circle-bottom">
                                {this.props.uv_strength}uw/cm
                            </div>
                        </div>
                    </div>
                    }
                    {this.props.temp_enabled === true &&
                    <div className="detail-circle-panel">
                        <div className="detail-circle">
                            <div className="circle-top">
                                温度
                            </div>
                            <div className="circle-bottom">
                                {this.props.temp_out}℃
                            </div>
                        </div>
                    </div>
                    }
                    {this.props.temp_out_enabled === true &&
                    <div className="detail-circle-panel">
                        <div className="detail-circle">
                            <div className="circle-top">
                                室外温度
                            </div>
                            <div className="circle-bottom">
                                {this.props.temp}℃
                            </div>
                        </div>
                    </div>
                    }
                    {this.props.humid_enabled === true &&
                    <div className="detail-circle-panel">
                        <div className="detail-circle">
                            <div className="circle-top">
                                湿度
                            </div>
                            <div className="circle-bottom">
                                {this.props.humidity}%
                            </div>
                        </div>
                    </div>
                    }
                    {this.props.hcho_enabled === true &&
                    <div className="detail-circle-panel">
                        <div className="detail-circle">
                            <div className="circle-top">
                                甲醛
                            </div>
                            <div className="circle-bottom">
                                {this.props.hcho}PPM
                            </div>
                        </div>
                    </div>
                    }
                    {(this.props.tvoc_enabled !== null && this.props.co2 !== 0) &&
                    <div className="detail-circle-panel">
                        <div className="detail-circle">
                            <div className="circle-top">
                                TVOC
                            </div>
                            <div className="circle-bottom">
                                {this.props.co2}ppb
                            </div>
                        </div>
                    </div>
                    }
                </Card.Body>
            </Card>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorStatusComponent)
