import {OBTAIN_DEVICE, UPDATE_ONLINE_RATE, UPDATE_TOTAL_COUNT} from "../constant/action-type";

const initialState = {

    onlineRate: 0,
    totalCount: 0,
    runCount: 0,
    waitCount: 0,
    warningCount: 0,
    offlineCount: 0,
    devices: []
}

function machineReducer(state = initialState, action) {
    if (action.type === UPDATE_ONLINE_RATE) {
        return Object.assign({}, state, action.payload);
    }
    if (action.type === UPDATE_TOTAL_COUNT) {
        return Object.assign({}, state, action.payload);
    }
    if (action.type === OBTAIN_DEVICE) {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default machineReducer;