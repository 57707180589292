import {
    OBTAIN_SURPLUS_STATUS,
    OBTAIN_BACTERRICIDAL_STATUS,
    OBTAIN_RUNNING_STATUS,
    OBTAIN_SENSOR_STATUS,
    OBTAIN_USER_LIST
} from "../constant/action-type";

const initialState = {
    surplus_count: 1, //滤芯数量
    surplus_remains: [], //滤芯对应的累计使用时间
    uv_count: 1, //杀菌灯管数量
    uv_health: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], //杀菌因子的好坏
    uv_remains: [], //杀菌影子对应的累计使用时间
    surplus_refresh_time: 0,
    uv_refresh_time: 0,
    power_status: 0,
    power_controlled: false,
    run_status: 0,
    run_controlled: false,
    run_status_timestamp: 0,
    mode: 0,
    level: 1,
    level_controlled: false,
    voice: 0,
    voice_controlled: false,
    swing: 0,
    swing_controlled: false,
    o3: 0,
    o3_controlled: false,
    sensor_pm2_5: 0,
    sensor_co2: 0,
    sensor_temp: 0,
    sensor_temp_out: 0,
    sensor_tvoc: 0,
    sensor_uv_strength: 0,
    sensor_hcho: 0,
    sensor_ir: 0,
    sensor_humidity: 0,
    user_list: []
}

function statusReducer(state = initialState, action) {
    if (action.type === OBTAIN_SURPLUS_STATUS) {
        return Object.assign({}, state, action.payload);
    }
    if (action.type === OBTAIN_BACTERRICIDAL_STATUS) {
        return Object.assign({}, state, action.payload)
    }
    if (action.type === OBTAIN_RUNNING_STATUS) {
        if (state.power_controlled === true && state.power_status !== action.payload.power_status) {
            return Object.assign({}, state, {power_controlled: false});
        } else if (state.run_controlled === true && state.run_status !== action.payload.run_status) {
            return Object.assign({}, state, {run_controlled: false});
        } else if (state.level_controlled === true && state.level !== action.payload.level) {
            return Object.assign({}, state, {level_controlled: false})
        } else if (state.voice_controlled === true && state.voice !== action.payload.voice) {
            return Object.assign({}, state, {voice_controlled: false});
        } else if (state.swing_controlled === true && state.swing !== action.payload.swing) {
            return Object.assign({}, state, {swing_controlled: false});
        } else if (state.o3_controlled === true && state.o3 !== action.payload.o3) {
            return Object.assign({}, state, {o3_controlled: false});
        }
        return Object.assign({}, state, action.payload);
    }
    if (action.type === OBTAIN_SENSOR_STATUS) {
        return Object.assign({}, state, action.payload);
    }
    if (action.type === OBTAIN_USER_LIST) {
        return Object.assign({}, state, action.payload)
    }
    return state;
}

export default statusReducer