import axios from './request';

let base = "http://47.103.193.179:9090/Juguang";

function obtainUsers(name, page, size) {
    console.log("name: " + name + ", page: " + page + ", size: " + size)
    let url = base + "/user/byName?";
    let params = new URLSearchParams();
    // params.set("name", "")
    params.set("source", "system")
    if (name !== undefined && name !== null) {
        params.set("name", name);
    }
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    params.set("sort", "createTime,desc")
    url = url + params.toString();
    return axios.get(url).then(response => {
        return response.data;
    })
}


function modifyUser(id, roleId, username, name, mobile, customerId, customerProxy) {
    let url = base + "/user/update";
    let form = new FormData();
    form.append("id", id);
    form.append("roleId", roleId);
    form.append("username", username);
    form.append("name", name);
    form.append("mobile", mobile);
    form.append("customerId", customerId);
    form.append("customerProxy", customerProxy);
    return axios.post(url, form).then(response => {
        return response.data;
    })
}

function changePassword(userId) {
    let url = base + "/user/changePassword";
    let form = new FormData();
    form.append("userId", userId);
    form.append("submitPassword", "123456");
    return axios.post(url, form).then(response => {
        return response.data
    })
}

function lock(id) {
    let url = base + "/user/" + id + "/locked";
    let form = new FormData();
    form.append("id", id);
    return axios.post(url, form).then(response => {
        return response.data;
    })
}

function unlock(id) {
    let url = base + "/user/" + id + "/unlocked";
    let form = new FormData();
    form.append("id", id);
    return axios.post(url, form).then(response => {
        return response.data;
    })
}

function remove(id) {
    let url = base + "/user/" + id;
    return axios.delete(url).then(response => {
        return response.data
    })
}

function locate(id) {
    let url = "http://47.103.193.179:9090/hospital/profile/locate?userId=" + id;
    return axios.get(url).then(response => {
        return response.data
    })
}

export const user_service = {
    obtainUsers, modifyUser, changePassword, locate, lock, unlock, remove
}
