import axios from './request';

let base = "http://47.103.193.179:9090/Juguang";

function obtainCity() {
    let url = base + "/city"
    return axios.get(url).then(response => {
        console.log(response)
    })
}

function obtainCityByName(name) {
    let url = base + "/city/byName?name=" + name;
    return axios.get(url).then(response => {
        response = response.data;
        if (response.errorCode === 0) {
            console.log(response.data[0])
            return response.data[0];
        } else {
            return {};
        }
    })
}

function obtainCityByProvinceId(provinceId) {
    let url = base + "/city/byProvinceId?provinceId=" + provinceId;
    return axios.get(url).then(response => {
        console.log(response)
    })
}

function obtainCityById(cityid) {

}

function obtainGMLocation(address) {
    let url = "https://microservice.gmair.net/location/address/resolve";
    let form = new FormData();
    form.append("address", address);
    return axios.post(url, form).then(response => {
        return response.data;
    })
}

export const location_service = {
    obtainCity, obtainCityByName, obtainGMLocation
}