import React from 'react'

import {Card} from 'react-bootstrap'
import {util_service} from "../../service/util";

class BasicInfo extends React.Component {

    render() {
        const base = "http://pc.jsjuguang.com/platform";
        return (
            <Card className="detail-card">
                <Card.Header>基本信息</Card.Header>
                <Card.Body className="basic-panel">
                    <div className="basic-left">
                        <div className="basic-image">
                            {this.props.deviceImage !== "" &&
                            <img src={base + this.props.deviceImage} className="image-style"/>
                            }
                            <div>(设备图片)</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">在线状态：</div>
                            <div
                                className="text-value2">{this.props.isOnline === true ? "在线" : "离线"}</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">设备型号：</div>
                            <div className="text-value2">{util_service.format(this.props.deviceClass)}</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">产品MAC：</div>
                            <div className="text-value2">{this.props.mac}</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">所属部门：</div>
                            <div className="text-value2">{this.props.department}</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">所在位置：</div>
                            <div className="text-value2">{this.props.location}</div>
                        </div>
                    </div>
                    <div className="basic-left">
                        <div className="basic-image">
                            <img src={base + this.props.deviceQrcode} className="image-style"/>
                            <div>(型号二维码)</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">设备名称：</div>
                            <div className="text-value2">{util_service.format(this.props.deviceName)}</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">产品代码：</div>
                            <div className="text-value2">{this.props.deviceCode}</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">所属客户：</div>
                            <div className="text-value2">{this.props.company}</div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">软件版本：</div>
                            <div
                                className="text-value2">{this.props.softVersion}(最新版{this.props.latestVersion})
                            </div>
                        </div>
                        <div className="text-row">
                            <div className="text-key">硬件版本：</div>
                            <div className="text-value2">{this.props.firmVersion}</div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )

    }
}

export default BasicInfo