import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import * as router from 'react-router-dom';
import {connect} from "react-redux";
import {Container, Nav, NavItem, NavLink, Badge, DropdownToggle, DropdownMenu,Breadcrumb, BreadcrumbItem} from 'reactstrap';
import Clock from '../../views/Clock/Clock'

import {
    AppAside,
    AppAsideToggler,
    AppBreadcrumb2 as AppBreadcrumb,
    AppFooter,
    AppHeader,
    AppHeaderDropdown,
    AppNavbarBrand,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    // AppSidebarNav as AppSidebarNav,
    AppSidebarNav2 as AppSidebarNav,
    AppSidebarToggler,
} from '@coreui/react/lib';
// sidebar nav config
import navigation from '../../_nav.js';
// routes config
import routes from '../../routes.js';

import logo from '../../assets/images/brand/logo.svg'
import sygnet from '../../assets/images/brand/sygnet.svg'

import {saveCredential} from "../../action";
import {util_service} from "../../service/util";


function mapStateToProps(state) {
    return {
        authenticated: state.userInfoReducer.authenticated,
        name: state.userInfoReducer.name,
        id: state.userInfoReducer.id,
    }
}

const mapDispatchToProps = {
    saveCredential
}

class DefaultLayoutComponent extends Component {

    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    logout = () => {
        util_service.clearCredential();
        window.location.href = '/';
    }

    render() {
        const {authenticated} = this.props;
        if (!authenticated) {
            //读取localStorage
            let authenticated = util_service.loadLocal("authenticated");
            if (authenticated === null || authenticated === undefined || !authenticated) {
                console.log("auth: " + authenticated)
                //跳转到登录界面
                return (<Redirect from="/" to="/login"/>)
            }else {
                this.props.saveCredential(util_service.loadCredential());
            }
        }
        return (
            <div className="app">
                <AppHeader fixed>
                    <AppSidebarToggler className="d-lg-none" display="md" mobile/>
                    <AppNavbarBrand
                        full={{src: logo, width: 89, height: 25, alt: 'Juguang Logo'}}
                        minimized={{src: sygnet, width: 30, height: 30, alt: 'Juguang Logo'}}
                    />
                    <AppSidebarToggler className="d-md-down-none" display="lg"/>
                    <Nav className="d-md-down-none" navbar>
                        空气消毒器智能监控系统
                    </Nav>
                    <Nav className="ml-auto" navbar>
                        <NavItem className="d-md-down-none">
                            <NavLink className="flex-style cursor-style" onClick={(e)=>{
                                // alert(this.props.id)
                                window.open("http://47.103.193.179:9090/hospital/report/"+this.props.id+"/daily")
                            }}><i className="cil-data-transfer-down icons font-xl"></i>&nbsp;下载</NavLink>
                        </NavItem>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <NavItem className="d-md-down-none">
                            <NavLink className="flex-style cursor-style"><i className="cil-user icons icons font-xl d-block"></i>&nbsp;{this.props.name}</NavLink>
                        </NavItem>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <NavItem className="d-md-down-none">
                            <NavLink className="cursor-style" onClick={this.logout}>退出</NavLink>
                        </NavItem>
                    </Nav>
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader/>
                        <AppSidebarForm/>
                        {/*<AppSidebarNav navConfig={navigation} {...this.props} />*/}
                        <AppSidebarNav navConfig={navigation} router={router}/>
                        <AppSidebarFooter/>
                        <Clock/>
                        <AppSidebarMinimizer/>
                    </AppSidebar>
                    <main className="main">
                        {/*<AppBreadcrumb appRoutes={routes}/>*/}
                        {/*<AppBreadcrumb appRoutes={routes} router={router}/>*/}
                        <Container fluid>
                            <Switch>
                                {routes.map((route, idx) => {
                                        return route.component ? (
                                                <Route key={idx} path={route.path} exact={route.exact} name={route.name}
                                                       render={props => (
                                                           <route.component {...props} />
                                                       )}/>)
                                            : (null);
                                    },
                                )}
                                <Redirect from="/" to="/login"/>
                            </Switch>
                        </Container>
                    </main>
                </div>
                <AppFooter>
                    <span>&copy; 2020 江苏巨光光电科技有限公司</span>
                </AppFooter>
            </div>
        );
    }
}

const DefaultLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(DefaultLayoutComponent);

export default DefaultLayout;
