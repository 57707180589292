import axios from './request'

function obtainOutPMByCityId(city_id) {
    let url = "/outPm/" + city_id;
    return axios.get(url).then(response => {
        response = response.data;
        return response
    })
}

function obtainGMOutPM25ById(city_id) {
    let url = "https://microservice.gmair.net/airquality/latest/" + city_id;
    return axios.get(url).then(response => {
        console.log(JSON.stringify(response))
        return response.data
    })
}

function obtainGMHourlyOutPM25ById(city_id) {
    let url = "https://microservice.gmair.net/airquality/hourly/cityAqi/" + city_id;
    return axios.get(url).then(response => {
        return response.data;
    })
}

export const airquality_service = {
    obtainOutPMByCityId, obtainGMOutPM25ById, obtainGMHourlyOutPM25ById
}