import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {Col, Container, Row, Table, Tooltip} from 'react-bootstrap'
// import {Tooltip} from '@material-ui/core'

import ReactEcharts from 'echarts-for-react';

import '../../scss/custom.scss';

import {machine_service} from "../../service/machine";
import {log_service} from "../../service/log";
import {airquality_service} from "../../service/airquality";

import {
    updateOnlineRate,
    updateTotalCount,
    obtainDeviceAlarms4User,
    obtainSysLog,
    obtainDeviceAlarms,
    obtainCurrentAirQuality
} from "../../action";

import {connect} from "react-redux";
import {util_service} from "../../service/util";
import LineChart from "./lineChart";
import {alarm_service} from "../../service/alarm";
import {datetimeService} from "../../service/datetime";

function mapStateToProps(state) {
    return {
        userid: state.userInfoReducer.id,
        onlineRate: state.machineReducer.onlineRate,
        totalCount: state.machineReducer.totalCount,
        runCount: state.machineReducer.runCount,
        waitCount: state.machineReducer.waitCount,
        warningCount: state.machineReducer.warningCount,
        offlineCount: state.machineReducer.offlineCount,
        sysLogCount: state.logReducer.totalCount,
        sysLog: state.logReducer.logs,
        warningRecords: state.alarmReducer.dataList,
        cityid: state.locationReducer.city_id,
        pm2_5: state.airReducer.pm2_5,
        aqi: state.airReducer.aqi,
        aqiLevel: state.airReducer.aqiLevel,
        pm10: state.airReducer.pm10
    }
}

const mapDispatchToProps = {
    updateOnlineRate,
    updateTotalCount,
    obtainDeviceAlarms4User,
    obtainSysLog,
    obtainDeviceAlarms,
    obtainCurrentAirQuality
}

class DashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.refresh = this.refresh.bind(this);
        this.state = {
            colors: ["item-left-excellent", "item-left-good", "item-left-moderate", "item-left-poor", "item-left-poor2", "item-left-poor3"],
            datas: [{name: '测试部门一', quantity: 2, id: '111'}, {name: '测试部门二', quantity: 5, id: '222'}, {
                name: '测试部门三',
                quantity: 1,
                id: '333'
            },
                {name: '测试部门四', quantity: 6, id: '444'}, {name: '测试部门五', quantity: 2, id: '555'}, {
                    name: '测试部门六',
                    quantity: 10,
                    id: '666'
                }, {name: '测试部门六', quantity: 10, id: '666'}]
        }
    }

    componentDidMount() {
        //获取设备部门
        machine_service.obtainDevice(null, 0, 9999).then(response => {
            let list = response.data.datas;
            let datas = [];
            let ids = [];
            let totalCount = response.data.totalCount
            let onlineCount = 0;
            let runCount = 0;
            let waitCount = 0;
            let warningCount = 0;
            for (let i = 0; i < list.length; i++) {
                if (list[i].isOnline === true) {
                    onlineCount++;
                    if (list[i].deviceStatus != undefined && list[i].deviceStatus.winderror === true) {
                        console.log("wind error" + list[i].deviceStatus.winderror)
                        warningCount++;
                        continue;
                    }
                    if (list[i].deviceStatus != undefined && (list[i].deviceStatus.power === 1 && list[i].deviceStatus.run === 1)) {
                        runCount++;
                        continue;
                    } else {
                        waitCount++;
                        continue
                    }
                }
                if (ids.indexOf(list[i].customerProxy) === -1) {
                    ids.push(list[i].customerProxy);
                }
            }
            this.props.updateTotalCount({
                totalCount: totalCount,
                runCount: runCount,
                waitCount: waitCount,
                warningCount: warningCount,
                offlineCount: totalCount - runCount - waitCount - warningCount
            })
            let onlineRate = util_service.float2percent(onlineCount, totalCount);
            this.props.updateOnlineRate({onlineRate: onlineRate})
            for (let i = 0; i < ids.length; i++) {
                let json = {}
                json['quantity'] = 0
                for (let j = 0; j < list.length; j++) {
                    if (ids[i] === list[j].customerProxy) {
                        json['id'] = list[j].customerProxy;
                        json['name'] = list[j].customerProxyName;
                        json['quantity'] = json['quantity'] + 1
                    }
                }
                datas.push(json)
            }
            console.log(datas)
            this.setState({
                datas: datas
            })
        })
        // 获取设备在线率
        alarm_service.obtainDeviceAlarmsByUserid(this.props.userid).then(response => {
            response = response.data;
            let alarmTotalCount = response.totalCount;
            this.props.obtainDeviceAlarms4User({alarm4UserCount: alarmTotalCount})
        })
        log_service.obtainSysLog().then(response => {
            response = response.data;
            this.props.obtainSysLog({
                size: response.size,
                page: response.page,
                upPage: response.upPage,
                nextPage: response.nextPage,
                totalCount: response.totalCount,
                totalPage: response.totalPage,
                firstResult: response.firstResult,
                lastResult: response.lastResult,
                logs: response.datas
            })
        })
        alarm_service.obtainDeviceAlarms().then(response => {
            let data = response.data;
            this.props.obtainDeviceAlarms({
                size: data.size,
                page: data.page,
                upPage: data.upPage,
                nextPage: data.nextPage,
                totalCount: data.totalCount,
                totalPage: data.totalPage,
                firstResult: data.firstResult,
                lastResult: data.lastResult,
                dataList: data.datas
            })
        });
        if (this.props.cityid !== undefined && this.props.cityid !== "") {
            airquality_service.obtainGMOutPM25ById(this.props.cityid).then(response => {
                // response = response.data;
                // if (response === null) {
                //     console.log("当前城市无PM2.5的数据")
                // } else {
                //
                // }
                response = response.data[0];
                this.props.obtainCurrentAirQuality({
                    aqi: response.aqi,
                    aqiLevel: response.aqiLevel,
                    co: response.co,
                    no2: response.no2,
                    o3: response.o3,
                    pm2_5: response.pm2_5,
                    pm10: response.pm10,
                    so2: response.so2
                })

            })
        }
        this.refresh();
        setInterval(function () {
            this.refresh();
        }.bind(this), 10000)
    }

    componentWillReceiveProps(next) {
        if (next.cityid !== this.props.cityid) {
            airquality_service.obtainGMOutPM25ById(next.cityid).then(response => {
                // response = response.data;
                // if (response === null) {
                //     console.log("当前城市无PM2.5的数据")
                // } else {
                //
                // }
                response = response.data[0];
                this.props.obtainCurrentAirQuality({
                    aqi: response.aqi,
                    aqiLevel: response.aqiLevel,
                    co: response.co,
                    no2: response.no2,
                    o3: response.o3,
                    pm2_5: response.pm2_5,
                    pm10: response.pm10,
                    so2: response.so2
                })

            })
        }
    }

    refresh = () => {
        machine_service.obtainDevice(null, 0, 9999).then(response => {
            let list = response.data.datas;
            let totalCount = response.data.totalCount
            let onlineCount = 0;
            let runCount = 0;
            let waitCount = 0;
            let warningCount = 0;
            for (let i = 0; i < list.length; i++) {
                if (list[i].isOnline === true) {
                    onlineCount++;
                    if (list[i].deviceStatus != undefined && list[i].deviceStatus.winderror === true) {
                        console.log("wind error" + list[i].deviceStatus.winderror)
                        warningCount++;
                        continue;
                    }
                    if (list[i].deviceStatus != undefined && (list[i].deviceStatus.power === 1 && list[i].deviceStatus.run === 1)) {
                        runCount++;
                        continue;
                    } else {
                        waitCount++;
                        continue
                    }
                }
            }
            this.props.updateTotalCount({
                totalCount: totalCount,
                runCount: runCount,
                waitCount: waitCount,
                warningCount: warningCount,
                offlineCount: totalCount - runCount - waitCount - warningCount
            })
            let onlineRate = util_service.float2percent(onlineCount / totalCount);
            this.props.updateOnlineRate({onlineRate: onlineRate})
        })
        // machine_service.obtainOnlineRate().then(response => {
        //     this.props.updateOnlineRate({onlineRate: response})
        // });
        // util_service.overview().then(response => {
        //     let totalCount = response.data.device;
        //     this.props.updateTotalCount({totalCount: totalCount});
        // });
    }

    render() {
        let {datas, colors} = this.state;
        const option = {
            tooltip: {
                formatter: "{a} <br/>{b} : {c}%"
            },
            series: [
                {
                    name: '设备在线率',
                    type: 'gauge',
                    detail: {formatter: '{value}%'},
                    data: [{value: this.props.onlineRate, name: '在线率'}],
                    axisLine: {
                        lineStyle: {
                            color: [[0.2, '#c23531'], [0.8, '#63869e'], [1, '#91c7ae']]
                        }
                    }
                }
            ]
        };

        const pie_option1 = {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c}台 ({d}%)'
            },
            // legend: {
            //     orient: 'vertical',
            //     left: 10,
            //     data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
            // },
            color: ['rgb(102,204,153)', 'rgb(51,153,204)', 'rgb(204,153,0)', 'rgb(204,153,153)'],
            series: [
                {
                    name: '设备',
                    type: 'pie',
                    radius: ['70%', '80%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: true,
                            position: 'center',
                            fontSize: '30',
                            color: 'rgb(102,204,153)',
                            formatter: this.props.totalCount + "台",
                        }
                    },
                    emphasis: {
                        label: {
                            show: false,
                            // fontSize: '30',
                            // fontWeight: 'bold',
                            // formatter: '{b}{c}台',
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: this.props.runCount, name: '运行'},
                        {value: this.props.waitCount, name: '待机'},
                        {value: this.props.warningCount, name: '故障'},
                        {value: this.props.offlineCount, name: '离线'},
                    ]
                }
            ]
        };

        const pie_option2 = {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            // legend: {
            //     orient: 'vertical',
            //     left: 10,
            //     data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
            // },
            color: ['rgb(102,204,153)', 'rgb(51,153,204)', 'rgb(204,153,0)', 'rgb(204,153,153)'],
            series: [
                {
                    name: '空气质量',
                    type: 'pie',
                    radius: ['70%', '80%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: true,
                            position: 'center',
                            fontSize: '30',
                            color: 'rgb(102,204,153)',
                            formatter: "10间",
                        }
                    },
                    emphasis: {
                        label: {
                            show: false,
                            // fontSize: '30',
                            // fontWeight: 'bold',
                            // formatter: '{b}{c}台',
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: 14, name: '优'},
                        {value: 2, name: '良'},
                    ]
                }
            ]
        };

        return (
            <div className="animated fadeIn">
                <div className='chart-box'>
                    <Container>
                        {/*<div>*/}
                        {/*<div className='value'>{this.props.totalCount} <span>台</span></div>*/}
                        {/*<div className='footer'>共计</div>*/}
                        {/*</div>*/}
                        <Row>
                            <Col xl='4'>
                                <Container className='complete-ratio'>
                                    <div className='topic'>设备在线率</div>
                                    <ReactEcharts option={option} theme='light'/>
                                </Container>
                            </Col>
                            <Col xl='2'>
                                <Container className='compare-box'
                                           style={{backgroundColor: util_service.tell_pm2_5_color(this.props.aqi)}}>
                                    <div className="compare-box-item">城市空气质量</div>
                                    <div className="compare-box-item"><span
                                        className="compare-box-item-value">{this.props.aqi}</span>
                                        {/*<span className="compare-box-item-unit">&nbsp;&nbsp;μg/m³</span>*/}
                                    </div>
                                    <div className="compare-box-item">{this.props.aqiLevel}</div>
                                </Container>
                                <Container className='compare-box' style={{backgroundColor: 'gray'}}>
                                    <div className="compare-box-item">室内空气质量</div>
                                    <div className="compare-box-item"><span
                                        className="compare-box-item-value">--</span><span
                                        className="compare-box-item-unit">&nbsp;&nbsp;μg/m³</span></div>
                                    <div className="compare-box-item">--</div>
                                </Container>
                            </Col>
                            <Col xl='3'>
                                <Container className='complete-ratio'>
                                    <div className='topic'>设备状态概览</div>
                                    <ReactEcharts style={{height: '180px'}} option={pie_option1}/>
                                    <Container className='circle-area'>
                                        <div className='circle'>
                                            {/*<div className='value'>{this.props.totalCount} <span>台</span></div>*/}
                                            {/*<div className='footer'>共计</div>*/}
                                            <div className='footer label-list'>
                                                <div className='label item'>
                                                    <div className="item-left">
                                                        <div className="color-item item-left-excellent"></div>
                                                    </div>
                                                    <div className="item-middle">运行</div>
                                                    <div className="item-right">{this.props.runCount}台</div>
                                                </div>
                                                <div className='label item'>
                                                    <div className="item-left">
                                                        <div className="color-item item-left-good"></div>
                                                    </div>
                                                    <div className="item-middle">待机</div>
                                                    <div className="item-right">{this.props.waitCount}台</div>
                                                </div>
                                                <div className='label item'>
                                                    <div className="item-left">
                                                        <div className="color-item item-left-moderate"></div>
                                                    </div>
                                                    <div className="item-middle">故障</div>
                                                    <div className="item-right">{this.props.warningCount}台</div>
                                                </div>
                                                <div className='label item'>
                                                    <div className="item-left">
                                                        <div className="color-item item-left-poor"></div>
                                                    </div>
                                                    <div className="item-middle">离线</div>
                                                    <div
                                                        className="item-right">{this.props.offlineCount}台
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Container>
                                </Container>
                            </Col>
                            <Col xl='3'>
                                <Container className='complete-ratio'>
                                    <div className='topic'>设备分布概览</div>
                                    {/*<ReactEcharts style={{height: '180px'}} option={pie_option2}/>*/}
                                    <Container className='circle-area'>
                                        <div className='circle'>
                                            <div className='footer label-list'>
                                                {datas.map((item, index) => {
                                                    if (index < 6) {
                                                        return (
                                                            <div className='label_2 item_2'>
                                                                <div className="item-left">
                                                                    <div
                                                                        className={"color-item " + colors[index]}></div>
                                                                </div>
                                                                <div className="item-middle">{item.name}</div>
                                                                <Tooltip title={item.name}>
                                                                    <div className="item-middle">{item.name}</div>
                                                                </Tooltip>
                                                                <div className="item-right">{item.quantity}台</div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                            <a href="/#/device" className="a-style">查看更多</a>
                                        </div>
                                    </Container>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl='6'>
                                <Container className="air-panel">
                                    <div className='air-item'>
                                        <div className="air-item-item"><i className="cil-grain"/></div>
                                        <div className="air-item-item air-item-value">{this.props.pm2_5}</div>
                                        <div className="air-item-item air-item-attr">PM2.5</div>
                                        <div className="air-item-item air-item-unit">μg/m³</div>
                                    </div>
                                    <div className='air-item'>
                                        <div className="air-item-item"><i className="cil-room"/></div>
                                        <div className="air-item-item air-item-value">{this.props.pm10}</div>
                                        <div className="air-item-item air-item-attr">PM10</div>
                                        <div className="air-item-item air-item-unit">μg/m³</div>
                                    </div>
                                    <div className='air-item' style={{backgroundColor: 'gray'}}>
                                        <div className="air-item-item"><i className="cil-paint-bucket"/></div>
                                        <div className="air-item-item air-item-value">--</div>
                                        <div className="air-item-item air-item-attr">TVOC</div>
                                        <div className="air-item-item air-item-unit">mg/m³</div>
                                    </div>
                                    <div className='air-item' style={{backgroundColor: 'gray'}}>
                                        <div className="temp-item air-item-item">
                                            <div className="temp-item-item"><i className="cil-sun"/></div>
                                            <div className="temp-item-item temp-item-value">-</div>
                                            <div className="temp-item-item">℃</div>
                                        </div>
                                        <div className="temp-item air-item-item">
                                            <div className="temp-item-item"><i className="cil-drop"/></div>
                                            <div className="temp-item-item temp-item-value">-</div>
                                            <div className="temp-item-item">%</div>
                                        </div>
                                        <div className="air-item-item air-item-attr">温湿度</div>
                                    </div>
                                </Container>
                            </Col>
                            <Col xl='6'>
                                <Container className='log-panel'>
                                    <div className='log-top'>
                                        <div className="log-top-left"><i className="cil-clipboard"></i>&nbsp;系统日志</div>
                                        <Link to="/device/syslog" className="log-top-right">查看更多</Link>
                                    </div>
                                    <div className="log-detail">
                                        {this.props.sysLog.length > 0 && this.props.sysLog.map((item, index) => {
                                            return (<div className="log-detail-item" key={item.id}>
                                                <div className="log-detail-item-left">
                                                    <i className="cil-envelope-closed"></i>&nbsp;{item.deviceName}：{item.name}&nbsp;{item.operation}
                                                </div>
                                                <div className="log-detail-item-right">
                                                    {datetimeService.formatTimeStampToDateTime(item.createTime)}
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl='6'>
                                <Container className="chart-panel">
                                    <LineChart/>
                                </Container>
                            </Col>
                            <Col xl='6'>
                                <Container className='log-panel alarm-panel'>
                                    <div className='log-top'>
                                        <div className="log-top-left"><i className="cil-bell"></i>&nbsp;设备报警</div>
                                        <Link className="log-top-right" to="/warning">查看更多</Link>
                                    </div>
                                    <div className="log-detail">
                                        {this.props.warningRecords.length > 0 && this.props.warningRecords.map((item, index) => {
                                            // console.log(item)
                                            return (<div className="log-detail-item" key={item.id}>
                                                <div className="log-detail-item-left">
                                                    <i className="cil-envelope-closed"></i>&nbsp;{item.customerName}({item.mac}), {item.alertName}
                                                </div>
                                                <div className="log-detail-item-right">
                                                    {datetimeService.formatTimeStampToDateTime(item.createTime)}
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);

export default Dashboard;

