import React, {Component} from 'react';
import {Paper} from '@material-ui/core'
import '../../scss/userCustom.scss'

class ShowItem extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Paper className="show-item-index">
                <div>{!this.props.iconName?<i className={this.props.icon}></i>:this.props.iconName}</div>
                <div>{this.props.value}{this.props.unit}</div>
                <div>{this.props.title}</div>
            </Paper>
        );
    }

}

export default ShowItem
