import React from 'react'

import {connect} from 'react-redux'

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    TextField
} from '@material-ui/core';

import {KeyboardTimePicker} from 'material-ui-thai-datepickers';

import {obtainControlConfig} from "../../action";
import {datetimeService} from "../../service/datetime";
import '../../scss/client.scss'
import {util_service} from "../../service/util";

function mapStateToProps(state) {
    return {
        h_control_1s: state.timingReducer.h_control_1s,
        m_control_1s: state.timingReducer.m_control_1s,
        h_control_1e: state.timingReducer.h_control_1e,
        m_control_1e: state.timingReducer.m_control_1e,
        h_control_2s: state.timingReducer.h_control_2s,
        m_control_2s: state.timingReducer.m_control_2s,
        h_control_2e: state.timingReducer.h_control_2e,
        m_control_2e: state.timingReducer.m_control_2e,
        h_control_3s: state.timingReducer.h_control_3s,
        m_control_3s: state.timingReducer.m_control_3s,
        h_control_3e: state.timingReducer.h_control_3e,
        m_control_3e: state.timingReducer.m_control_3e,
        h_control_4s: state.timingReducer.h_control_4s,
        m_control_4s: state.timingReducer.m_control_4s,
        h_control_4e: state.timingReducer.h_control_4e,
        m_control_4e: state.timingReducer.m_control_4e,
        h_control_5s: state.timingReducer.h_control_5s,
        m_control_5s: state.timingReducer.m_control_5s,
        h_control_5e: state.timingReducer.h_control_5e,
        m_control_5e: state.timingReducer.m_control_5e,
        h_control_6s: state.timingReducer.h_control_6s,
        m_control_6s: state.timingReducer.m_control_6s,
        h_control_6e: state.timingReducer.h_control_6e,
        m_control_6e: state.timingReducer.m_control_6e,
        control_1_enabled: state.timingReducer.control_1_enabled,
        control_2_enabled: state.timingReducer.control_2_enabled,
        control_3_enabled: state.timingReducer.control_3_enabled,
        control_4_enabled: state.timingReducer.control_4_enabled,
        control_5_enabled: state.timingReducer.control_5_enabled,
        control_6_enabled: state.timingReducer.control_6_enabled,
        control_1_time: state.timingReducer.control_1_time
    }
}

const mapDispatchToProps = {
    obtainControlConfig
}

class ControlModeComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            list: [
                {start: "00:00", end: "00:00", check: false},
                {start: "00:00", end: "00:00", check: false},
                {start: "00:00", end: "00:00", check: false},
                {start: "00:00", end: "00:00", check: false},
                {start: "00:00", end: "00:00", check: false},
                {start: "00:00", end: "00:00", check: false},
            ]
        };
        this.timeToArr = this.timeToArr.bind(this)
    }

    componentDidMount() {

    }

    componentWillReceiveProps(next) {

    }


    //todo 调节之后调用此方法进行设备，当取消勾选时，start_h, start_m, end_h, end_m都设置为0, flag 为勾选与否
    timing_control(i, start_h, start_m, end_h, end_m, enabled) {
        let sh = i + "b_h";
        let sm = i + "b_m";
        let eh = i + "e_h";
        let em = i + "e_m";
        let data = {};
        data[sh] = start_h;
        data[sm] = start_m;
        data[eh] = end_h;
        data[em] = end_m;
        this.props.send_message("/client/XD/" + this.props.mac + "/set_timeseg", JSON.stringify(data));
        setTimeout(() => {
            this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "timeseg"}))
        }, 1000)

    }

    time_enable_control(i, enabled) {
        let flag = "timeseg" + i;
        let message = JSON.parse("{\"" + flag + "\":" + enabled + "}")
        this.props.send_message("/client/XD/" + this.props.mac + "/cmd", JSON.stringify(message))
        setTimeout(() => {
            this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "timeseg"}))
        }, 1000)
    }

    timeToArr(time) {
        let arr = time.split(":")
        let result = []
        for (let i = 0; i < arr.length; i++) {
            result.push(parseInt(arr[i]))
        }
        return result
    }

    render() {
        let that = this;
        if (this.props.isOnline === true) {
            return (
                <div className="time-content">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            <div>
                                <span className="time-content-top">程控模式：</span>（勾选为有效，反之无效）
                            </div>
                        </FormLabel>
                        <FormGroup>
                            <div className='check-item-row'>
                                <FormControlLabel className="check-item-left"
                                                  control={<Checkbox checked={this.props.control_1_enabled === 1}
                                                                     color="primary" onChange={() => {
                                                      let target_value = this.props.control_1_enabled === 1 ? 0 : 1;
                                                      this.time_enable_control(1, target_value);
                                                  }}/>}
                                                  label={"时段1"}
                                />
                                <TextField
                                    label="开始时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_1s, this.props.m_control_1s)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(1, h_control, m_control, this.props.h_control_1e, this.props.m_control_1e);
                                        this.props.obtainControlConfig({
                                            h_control_1s: h_control,
                                            m_control_1s: m_control
                                        })
                                    }}
                                />
                                <TextField
                                    label="结束时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_1e, this.props.m_control_1e)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(1, this.props.h_control_1s, this.props.m_control_1s, h_control, m_control,);
                                        this.props.obtainControlConfig({
                                            h_control_1e: h_control,
                                            m_control_1e: m_control
                                        })
                                    }}
                                />
                            </div>
                            <div className='check-item-row'>
                                <FormControlLabel className="check-item-left"
                                                  control={<Checkbox checked={this.props.control_2_enabled === 1}
                                                                     color="primary" onChange={() => {
                                                      let target_value = this.props.control_2_enabled === 1 ? 0 : 1;
                                                      this.time_enable_control(2, target_value);
                                                  }}/>}
                                                  label={"时段2"}
                                />
                                <TextField
                                    label="开始时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_2s, this.props.m_control_2s)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(2, h_control, m_control, this.props.h_control_2e, this.props.m_control_2e);
                                        this.props.obtainControlConfig({
                                            h_control_2s: h_control,
                                            m_control_2s: m_control
                                        })
                                    }}
                                />
                                <TextField
                                    label="结束时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_2e, this.props.m_control_2e)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(2, this.props.h_control_2s, this.props.m_control_2s, h_control, m_control,);
                                        this.props.obtainControlConfig({
                                            h_control_2e: h_control,
                                            m_control_2e: m_control
                                        })
                                    }}
                                />
                            </div>
                            <div className='check-item-row'>
                                <FormControlLabel className="check-item-left"
                                                  control={<Checkbox checked={this.props.control_3_enabled === 1}
                                                                     color="primary" onChange={() => {
                                                      let target_value = this.props.control_3_enabled === 1 ? 0 : 1;
                                                      this.time_enable_control(3, target_value);
                                                  }}/>}
                                                  label={"时段3"}
                                />
                                <TextField
                                    label="开始时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_3s, this.props.m_control_3s)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(3, h_control, m_control, this.props.h_control_3e, this.props.m_control_3e);
                                        this.props.obtainControlConfig({
                                            h_control_3s: h_control,
                                            m_control_3s: m_control
                                        })
                                    }}
                                />
                                <TextField
                                    label="结束时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_3e, this.props.m_control_3e)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(3, this.props.h_control_3s, this.props.m_control_3s, h_control, m_control,);
                                        this.props.obtainControlConfig({
                                            h_control_3e: h_control,
                                            m_control_3e: m_control
                                        })
                                    }}
                                />
                            </div>
                            <div className='check-item-row'>
                                <FormControlLabel className="check-item-left"
                                                  control={<Checkbox checked={this.props.control_4_enabled === 1}
                                                                     color="primary" onChange={() => {
                                                      let target_value = this.props.control_4_enabled === 1 ? 0 : 1;
                                                      this.time_enable_control(4, target_value);
                                                  }}/>}
                                                  label={"时段4"}
                                />
                                <TextField
                                    label="开始时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_4s, this.props.m_control_4s)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(4, h_control, m_control, this.props.h_control_4e, this.props.m_control_4e);
                                        this.props.obtainControlConfig({
                                            h_control_4s: h_control,
                                            m_control_4s: m_control
                                        })
                                    }}
                                />
                                <TextField
                                    label="结束时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_4e, this.props.m_control_4e)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(4, this.props.h_control_4s, this.props.m_control_4s, h_control, m_control,);
                                        this.props.obtainControlConfig({
                                            h_control_4e: h_control,
                                            m_control_4e: m_control
                                        })
                                    }}
                                />
                            </div>
                            <div className='check-item-row'>
                                <FormControlLabel className="check-item-left"
                                                  control={<Checkbox checked={this.props.control_5_enabled === 1}
                                                                     color="primary" onChange={() => {
                                                      let target_value = this.props.control_5_enabled === 1 ? 0 : 1;
                                                      this.time_enable_control(5, target_value);
                                                  }}/>}
                                                  label={"时段5"}
                                />
                                <TextField
                                    label="开始时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_5s, this.props.m_control_5s)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(5, h_control, m_control, this.props.h_control_5e, this.props.m_control_5e);
                                        this.props.obtainControlConfig({
                                            h_control_5s: h_control,
                                            m_control_5s: m_control
                                        })
                                    }}
                                />
                                <TextField
                                    label="结束时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_5e, this.props.m_control_5e)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(5, this.props.h_control_5s, this.props.m_control_5s, h_control, m_control,);
                                        this.props.obtainControlConfig({
                                            h_control_5e: h_control,
                                            m_control_5e: m_control
                                        })
                                    }}
                                />
                            </div>
                            <div className='check-item-row'>
                                <FormControlLabel className="check-item-left"
                                                  control={<Checkbox checked={this.props.control_6_enabled === 1}
                                                                     color="primary" onChange={() => {
                                                      let target_value = this.props.control_6_enabled === 1 ? 0 : 1;
                                                      this.time_enable_control(6, target_value);
                                                  }}/>}
                                                  label={"时段6"}
                                />
                                <TextField
                                    label="开始时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_6s, this.props.m_control_6s)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(6, h_control, m_control, this.props.h_control_6e, this.props.m_control_6e);
                                        this.props.obtainControlConfig({
                                            h_control_6s: h_control,
                                            m_control_6s: m_control
                                        })
                                    }}
                                />
                                <TextField
                                    label="结束时间"
                                    type="time"
                                    className="check-item-right"
                                    defaultValue={util_service.format_time(this.props.h_control_6e, this.props.m_control_6e)}
                                    onChange={(e) => {
                                        let target_value = this.timeToArr(e.target.value);
                                        let h_control = target_value[0];
                                        let m_control = target_value[1];
                                        this.timing_control(6, this.props.h_control_6s, this.props.m_control_6s, h_control, m_control,);
                                        this.props.obtainControlConfig({
                                            h_control_6e: h_control,
                                            m_control_6e: m_control
                                        })
                                    }}
                                />
                            </div>
                        </FormGroup>
                    </FormControl>
                </div>
            )
        } else {
            return (
                <div className="time-content">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            <div>
                                <span className="time-content-top">程控模式：</span>（勾选为有效，反之无效）
                            </div>
                        </FormLabel>
                    </FormControl>
                </div>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlModeComponent)
