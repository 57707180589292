import {OBTAIN_TIMING_CONFIG, OBTAIN_CONTROL_CONFIG} from "../constant/action-type"

const initialState = {
    h_time: 0,
    m_time: 0,
    h_control_1s: 0,
    m_control_1s: 0,
    h_control_1e: 0,
    m_control_1e: 0,
    h_control_2s: 0,
    m_control_2s: 0,
    h_control_2e: 0,
    m_control_2e: 0,
    h_control_3s: 0,
    m_control_3s: 0,
    h_control_3e: 0,
    m_control_3e: 0,
    h_control_4s: 0,
    m_control_4s: 0,
    h_control_4e: 0,
    m_control_4e: 0,
    h_control_5s: 0,
    m_control_5s: 0,
    h_control_5e: 0,
    m_control_5e: 0,
    h_control_6s: 0,
    m_control_6s: 0,
    h_control_6e: 0,
    m_control_6e: 0,
    control_1_enabled: 0,
    control_2_enabled: 0,
    control_3_enabled: 0,
    control_4_enabled: 0,
    control_5_enabled: 0,
    control_6_enabled: 0
}

function timingReducer(state = initialState, action) {
    if (action.type === OBTAIN_TIMING_CONFIG) {
        return Object.assign({}, state, action.payload)
    }
    if (action.type === OBTAIN_CONTROL_CONFIG) {
        return Object.assign({}, state, action.payload)
    }
    return state;
}

export default timingReducer;