import {combineReducers} from 'redux';

import alarmReducer from './alarm-reducer'
import credentialReducer from './credential-reducer'
import logReducer from './log-reducer'
import profileReducer from './profile-reducer';
import corpInfoReducer from './corpinfo-reducer';
import userInfoReducer from './userinfo-reducer';
import machineReducer from "./machine-reducer";
import userReducer from "./user-reducer";
import customerReducer from "./customer";
import locationReducer from "./location-reducer";
import airReducer from "./air-reducer";
import statusReducer from "./status-reducer"
import timingReducer from "./timing-reducer";

export default combineReducers({
    airReducer: airReducer,
    alarmReducer: alarmReducer,
    credentialReducer: credentialReducer,
    customerReducer: customerReducer,
    locationReducer: locationReducer,
    logReducer: logReducer,
    machineReducer: machineReducer,
    profileReducer: profileReducer,
    corpInfoReducer: corpInfoReducer,
    userInfoReducer: userInfoReducer,
    userReducer: userReducer,
    statusReducer: statusReducer,
    timingReducer: timingReducer
});