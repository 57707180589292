import React,{Component} from 'react'
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {Button} from "reactstrap";

class MyConfirm extends Component{
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={()=>{
                        this.props.onCancel(this.props)
                    }}>
                        取消
                    </Button>
                    <Button color="primary" onClick={()=>{
                        this.props.onOk(this.props)
                    }}>
                        确定
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default MyConfirm
