import React, {Component} from 'react';

import ReactEcharts from 'echarts-for-react';

import {connect} from "react-redux";

import {airquality_service} from "../../service/airquality";
import {datetimeService} from "../../service/datetime";
import {util_service} from "../../service/util";

function mapStateToProps(state) {
    return {
        city_id: state.locationReducer.city_id
    }
}

class LineChartComponent extends Component {

    constructor(props) {
        super(props);
        this.state= {
            xData: [],
            outData: [],
            inData: [],
        }
    }


    componentDidMount() {
        let that = this
        setInterval(()=> {
            that.getData(that.props)
        }, 10000);
    }

    componentWillReceiveProps(nextProps){
        this.getData(nextProps)
    }

    getData(props){
        if (props.city_id !== null && props.city_id !== "") {
            airquality_service.obtainGMHourlyOutPM25ById(props.city_id).then(response => {
                    if(response.responseCode=="RESPONSE_OK"){
                        response = response.data;
                        let xData = [],
                            outData = []
                        response.map((item,index)=>{
                            xData.push(datetimeService.formatTimeStampToDateHour(item.createTime))
                            outData.push(item.pm25)
                        })
                        outData = this.setNewXData(outData)
                        this.setState({
                            xData:xData,
                            outData:outData,
                        })
                        if(this.state.inData.length==0){
                            this.setState({
                                inData:util_service.randomInData(0,10,xData.length)
                            })
                        }
                    }
                }
            )
        }
    }

    setNewXData(list){
        let result = []
        for(let i=0;i<list.length;i++){
            if(list[i]==0){
                let item = 0
                if(i<=3){
                    item = this.setAverage(list,0,6)
                }else if(i>=21){
                    item = this.setAverage(list,18,list.length-1)
                }else {
                    item = this.setAverage(list,i-3,i+3)
                }
                result.push(item)
            }else {
                result.push(list[i])
            }
        }
        return result;
    }

    setAverage(list,start,end){
        let sum = 0
        let index = end-start+1;
        for(let i=start;i<=end;i++){
            if(list[i]==0){
                index--;
            }else {
                sum +=list[i]
            }
        }
        return parseInt(sum/index)
    }




    render() {
        let option = {
            color: ['#11C1F3', '#F282AA'],
            title: {
                text: '近日空气质量对比',
                show: true,
                textStyle: {
                    color: '#000',
                    fontWeight: 'normal',
                    fontSize: 12,
                    align: 'left'
                }
            },
            legend: {
                left: 'right',
                data: [{name: '室外'}, {name: '室内'}],
                align: 'left'
            },
            grid: {
                top: 38, left: '12%', right: '12%'
            },
            xAxis: {
                fontStyle: 'oblique',
                type: 'category',
                boundaryGap: false,
                data: this.state.xData
            },
            tooltip: {
                trigger: 'item'
            },
            toolbox: {
                show: false
            },
            calculable: true,
            yAxis: {
                name: '空气污染指数',
                splitLine: false,
                nameLocation: 'center',
                nameRotate: 90,
                type: 'value',
                axisLabel: {
                    formatter: '{value}',
                    inside: false,
                    margin: 5,
                },
                nameGap: 25,
                axisTick: {
                    show: false
                }
            },
            visualMap: [{
                seriesIndex: 0,
                show: false,
                dimension: 1,
                pieces: [
                    {gte: 25, color: `red`},            // (1500, Infinity]
                    {lt: 24.9999, color: `#11C1F3`}                 // (-Infinity, 5)
                ],
                outOfRange: {
                    color: '#11C1F3'
                }
            }],
            series: [
                {
                    name: '室内',
                    type: 'line',
                    data: this.state.inData,
                    symbol: 'emptyDiamond',
                    symbolSize: 6,
                    smooth: false,
                    lineStyle: {
                        color: '#11C1F3',
                        width: 3
                    },
                    showAllSymbol:true,
                    markLine: {
                        symbol: 'none',
                        data: [
                            // {
                            //     name: '平均线',
                            //     // 支持 'average', 'min', 'max'
                            //     type: 'average'
                            // },
                            {
                                name: 'PM2.5数值参考线',
                                yAxis: 25,
                                lineStyle: {
                                    color: '#11C1F3',
                                    width: 1.5,
                                    type: 'dashed'
                                },
                                label: {show: false, position: 'end'}
                            },
                        ]
                    }
                },
                {
                    name: '室外',
                    type: 'line',
                    data: this.state.outData,
                    symbol: 'emptyCircle',
                    symbolSize: 6,
                    smooth: false,
                    showAllSymbol:true,
                    lineStyle: {
                        color: '#F282AA',
                        width: 3
                    }
                }
            ]
        };

        return (
            <div>
                <ReactEcharts option={option} theme='light'/>
            </div>
        );
    }

}

const LineChart = connect(mapStateToProps, null)(LineChartComponent);

export default LineChart
