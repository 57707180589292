export default {
    items: [
        {
            name: '控制中心',
            url: '/dashboard',
            icon: 'cui-speedometer icons'
        },
        {
            name: '客户管理',
            url: '/customer',
            icon: 'cui-people icons'
        },
        {
            name: '设备管理',
            url: '/device',
            icon: 'cui-calculator icons'
        },
        {
            name: '用户管理',
            url: '/user',
            icon: 'cui-user icons'
        },
        {
            name: '报警信息',
            url: '/warning',
            icon: 'cui-note icons'
        }
    ]
};
