import React from 'react'
import {Button, Card, FormControl, InputGroup, Modal} from 'react-bootstrap'
import {connect} from 'react-redux'
import {machine_service} from "../../service/machine";
import {datetimeService} from "../../service/datetime";


import {obtainSurplus} from '../../action'

function mapStateToProps(state) {
    return {
        power_status: state.statusReducer.power_status,
        run_status: state.statusReducer.run_status,
        surplus_count: state.statusReducer.surplus_count,
        surplus_remains: state.statusReducer.surplus_remains,
        surplus_refresh_time: state.statusReducer.surplus_refresh_time
    }
}

const mapDispatchToProps = {
    obtainSurplus
}

class SurplusInfoComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            surplus_edit_index: 2,
            modal_show: false,
            value: 0
        }
    }

    componentDidMount() {
        if (this.props.mac !== undefined && this.props.mac !== "") {
            this.obtain_surplus_status(this.props.mac);
        }
    }

    componentWillReceiveProps(next) {
        if (next.mac !== "" && next.mac != this.props.mac) {
            this.obtain_surplus_status(next.mac)
        }
    }

    obtain_surplus_status(mac) {
        this.obtain_surplus_info(mac)
    }

    obtain_surplus_info = (mac) => {
        machine_service.obtainSurplusStatus(mac).then(response => {
            response = response.data;

            let count = 0;
            let data = [];
            if (response === null) return;
            if (response.filter1 != undefined) {
                count++;
                data.push(response.filter1);
            }
            if (response.filter2 != undefined) {
                count++;
                data.push(response.filter2);
            }
            if (response.filter3 != undefined) {
                count++;
                data.push(response.filter3);
            }
            this.props.obtainSurplus({
                surplus_count: count,
                surplus_remains: data,
                surplus_refresh_time: response.updateTime
            })
        })
    }

    show = () => {
        this.setState({
            modal_show: true
        })
    }

    hide = () => {
        this.setState({
            modal_show: false
        })
    }

    confirm_reset = () => {
        let mac = this.props.mac;
        if (this.state.value >= 0 && this.state.value <= this.props.meshLife) {
            let message = "{\"" + (this.state.surplus_edit_index) + "\":" + this.state.value + "}";
            this.props.send_message("/client/XD/" + mac + "/set_surplus", message);
            this.hide();
            setTimeout(() => {
                this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "surplus"}))
            }, 1000)
        }
    }

    read_value = (e) => {
        let value = e.target.value;
        if (value == "" || (value <= this.props.meshLife && value >= 0)) {
            this.setState({value: value});
        } else {
            return;
        }
    }

    reset_surplus = () => {
        this.show();
    }

    render() {

        // let surplus_count = this.props.surplus_count;
        // let surplus_remains = this.props.surplus_remains;
        // let surplus_refresh_time = this.props.surplus_refresh_time

        // let time = datetimeService.formatTimeStampToDateTime(surplus_refresh_time);

        // let remain = 0;
        // for (let i = 0; i < this.props.surplus_count; i++) {
        //     remain += this.props.surplus_remains[i];
        // }


        return (

            <div>
                <div className="lv-item">
                    <div className="lv-item-left">
                        滤网累计：
                    </div>
                    <div
                        className={this.props.surplus_remains[1] >= this.props.meshLife ? "lv-item-middle warning" : "lv-item-middle"}>
                        {this.props.surplus_remains[1]}小时
                    </div>
                    {this.props.power_status === 1 && this.props.run_status === 1 &&
                    <div className="lv-item-right active">
                        <i className="cil-sync"></i>&nbsp;
                        <span onClick={this.reset_surplus}>重置</span>
                    </div>
                    }
                    {(this.props.power_status !== 1 || this.props.run_status !== 1) &&
                    <div className="lv-item-right">
                        <i className="cil-sync"></i>&nbsp;
                        <span className='action'>重置</span>
                    </div>
                    }
                </div>

                {/*<div className="lv-main">*/}
                {/*{lv_list.map((item, index) => {*/}
                {/*return (<div className="lv-item">*/}
                {/*<div className="lv-item-left">*/}
                {/*{item.name}：*/}
                {/*</div>*/}
                {/*<div className="lv-item-middle">*/}
                {/*{item.value}*/}
                {/*</div>*/}
                {/*<div className="lv-item-right">*/}
                {/*<i className="cil-lightbulb"></i>&nbsp;重置*/}
                {/*</div>*/}
                {/*</div>)*/}
                {/*})}*/}
                {/*</div>*/}

                <Modal
                    show={this.state.modal_show}
                    onHide={this.hide}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>重置时间（最大为{this.props.meshLife}小时）</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder={"请输入需要设定的时间，不得超过" + this.props.meshLife} onChange={this.read_value}
                                value={this.state.value}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>小时</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hide}>
                            取消
                        </Button>
                        <Button variant="primary" onClick={this.confirm_reset}>确认</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurplusInfoComponent)
