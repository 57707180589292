import {SAVE_CREDENTIAL, UPDATE_ADDRESS} from "../constant/action-type";

const initialState = {
    authenticated: false,
    address: "",
    admin: false,
    appId: "",
    cityId: "",
    cityName: "",
    createAt: "",
    customerId: "",
    customerName: "",
    customerProxyId: "",
    customerProxyName: "",
    deviceAlertCount: "",
    deviceCount: "",
    districtId: "",
    districtName: "",
    email: "",
    houseArea: "",
    id: "",
    image: "",
    isAdmin: false,
    isCustomer: false,
    isUser: false,
    locked: false,
    loginDate: "",
    loginIp: "",
    mac: "",
    mobile: "",
    name: "",
    openid: "",
    permissions: [],
    provinceId: "",
    provinceName: "",
    publicNumerId: "",
    remark: "",
    role: "",
    roleId: "",
    roleName: "",
    source: "",
    username: "",
    location: '',
}

function userInfoReducer(state = initialState, action) {
    if (action.type === SAVE_CREDENTIAL) {
        return Object.assign({}, state, action.payload)
    }
    return state;
}

export default userInfoReducer;
