import axios from './request';

import {util_service} from "./util";

let base = "http://47.103.193.179:9090/Juguang"

function obtainDevice(key, page, size) {
    let url = base + "/device?";
    let params = new URLSearchParams();
    if (key !== undefined && key !== null) {
        params.set("key", key);
    }
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + params.toString();
    return axios.get(url).then(response => {
        return response.data;
    })
}

function obtainOnlineRate() {
    return util_service.overview().then(response => {
        response = response.data;
        let online = response.online;
        let total = response.device;
        return util_service.float2percent(online / total);
    })
}

//获取在线设备列表
function obtainOnline() {

}

//获取离线设备数量
function obtainOffline() {
    return util_service.overview().then(response => {
        response = response.data;
        let online = response.online;
        let offline = response.device - online;
        return offline;
    })
}

//获取设备信息
function profile(id) {
    let url = base + "/device/" + id;
    return axios.get(url).then(response => {
        response = response.data;
        return response
    })
}

//获取当前设备灯光状态
function obtainUVStatusAndTime(mac) {
    let url = base + "/deviceUVStatusAndTime/byMac/" + mac;
    return axios.get(url).then(response => {
        response = response.data;
        return response;
    })
}

//获取当前设备的滤芯状态
function obtainSurplusStatus(mac) {
    let url = base + "/deviceSurplus/byMac/" + mac;
    return axios.get(url).then(response => {
        response = response.data;
        return response;
    })
}

//获取运行状态
function obtainRunningStatus(mac) {
    let url = base + "/deviceStatus/byMac/" + mac;
    return axios.get(url).then(response => {
        response = response.data;
        return response;
    })
}

//获取设备传感器状态
function obtainSensorStatus(mac) {
    let url = base + "/deviceSensor/byMac/" + mac;
    return axios.get(url).then(response => {
        response = response.data;
        return response;
    })
}


//获取设备定时状态
function obtainTimingStatus(mac) {
    let url = base + "/deviceTime/byMac?mac=" + mac;
    return axios.get(url).then(response => {
        response = response.data;
        return response;
    })
}

//获取设备对应的用户
function obtainDeviceUsers(mac) {
    let url = base + "/deviceUser/byDevicenew/" + mac;
    return axios.get(url).then(response => {
        response = response.data;
        return response;
    })
}

export const machine_service = {
    obtainDevice,
    obtainOnlineRate,
    obtainOffline,
    obtainUVStatusAndTime,
    profile,
    obtainSurplusStatus,
    obtainRunningStatus,
    obtainSensorStatus,
    obtainTimingStatus,
    obtainDeviceUsers
}
