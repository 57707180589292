import {
    FILL_USERNAME,
    FILL_PASSWORD,
    GEN_CAPTCHA,
    SAVE_CREDENTIAL,
    SHOW_ALERT,
    HIDE_ALERT,
    FILL_CODE,
    GEN_ALERT_MSG,
    UPDATE_TIME,
    UPDATE_DATE,
    UPDATE_ONLINE_RATE,
    UPDATE_TOTAL_COUNT,
    OBTAIN_DEVICE_ALARM,
    OBTAIN_DEVICE_ALARM_4_USER,
    OBTAIN_USERS,
    OBTAIN_DEVICE, OBTAIN_CUSTOMER, OBTAIN_SYS_LOG, OBTAIN_LOCATION, OBTAIN_CURRENT_AIRQUALITY, OBTAIN_SURPLUS_STATUS,
    OBTAIN_RUNNING_STATUS, OBTAIN_SENSOR_STATUS, OBTAIN_USER_LIST, OBTAIN_TIMING_CONFIG, OBTAIN_CONTROL_CONFIG,
    OBTAIN_BACTERRICIDAL_STATUS
} from '../constant/action-type'

export const fillUsername = payload => ({
    type: FILL_USERNAME,
    payload
})

export const fillPassword = payload => ({
    type: FILL_PASSWORD,
    payload
})

export const genCaptcha = payload => ({
    type: GEN_CAPTCHA,
    payload
})

export const fillCode = payload => ({
    type: FILL_CODE,
    payload
})

export const showAlert = payload => ({
    type: SHOW_ALERT,
    payload
})

export const hideAlert = payload => ({
    type: HIDE_ALERT,
    payload
})

export const genAlertMsg = payload => ({
    type: GEN_ALERT_MSG,
    payload
})

export const saveCredential = payload => ({
    type: SAVE_CREDENTIAL,
    payload
})

export const updateTime = payload => ({
    type: UPDATE_TIME,
    payload
})

export const updateDate = payload => ({
    type: UPDATE_DATE,
    payload
})

export const updateOnlineRate = payload => ({
    type: UPDATE_ONLINE_RATE,
    payload
})

export const updateTotalCount = payload => ({
    type: UPDATE_TOTAL_COUNT,
    payload
})

export const obtainDeviceAlarms = payload => ({
    type: OBTAIN_DEVICE_ALARM,
    payload
})

export const obtainDeviceAlarms4User = payload => ({
    type: OBTAIN_DEVICE_ALARM_4_USER,
    payload
});

export const obtainUsers = payload => ({
    type: OBTAIN_USERS,
    payload
})

export const obtainDevice = payload => ({
    type: OBTAIN_DEVICE,
    payload
})

export const obtainCustomer = payload => ({
    type: OBTAIN_CUSTOMER,
    payload
})

export const obtainSysLog = payload => ({
    type: OBTAIN_SYS_LOG,
    payload
})

export const obtainLocation = payload => ({
    type: OBTAIN_LOCATION,
    payload
})

export const obtainCurrentAirQuality = payload => ({
    type: OBTAIN_CURRENT_AIRQUALITY,
    payload
})

export const obtainSurplus = payload => ({
    type: OBTAIN_SURPLUS_STATUS,
    payload
})

export const obtainBactericidal = payload => ({
    type: OBTAIN_BACTERRICIDAL_STATUS,
    payload
})


export const obtainRunningStatus = payload => ({
    type: OBTAIN_RUNNING_STATUS,
    payload
})


export const refreshSensorStatus = payload => ({
    type: OBTAIN_SENSOR_STATUS,
    payload
})

export const obtainUserList4Device = payload => ({
    type: OBTAIN_USER_LIST,
    payload
})

export const obtainTimingConfig = payload => ({
    type: OBTAIN_TIMING_CONFIG,
    payload
})

export const obtainControlConfig = payload => ({
    type: OBTAIN_CONTROL_CONFIG,
    payload
})