import axios from './request';

let base = "http://47.103.193.179:9090/Juguang";

//获取告警信息的数量
function obtainDeviceAlarmsCount(page, size) {
    let url = base + "/deviceAlert?";
    let params = new URLSearchParams();
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + params.toString();
    return axios.get(url).then(response => {
        response = response.data;
        return response.data.totalCount;
    })
}

//获取所有的告警信息
function obtainDeviceAlarms(key,page, size) {
    let url = base + "/deviceAlert?";
    let params = new URLSearchParams();
    if (key !== undefined && key !== null) {
        params.set("key", key);
    }
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + params.toString();
    return axios.get(url).then(response => {
        return response.data;
    })
}

//获取单台设备的告警信息
function obtainDeviceAlarmsByMac(mac, page, size) {
    let url = base + "/deviceAlert/byMac?mac=" + mac;
    let params = new URLSearchParams();
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + params.toString();
    return axios.get(url).then(response => {
        return response.data;
    })
}

//根据当前用户的userId获取设备的告警信息
function obtainDeviceAlarmsByUserid(userid, isFinish, read, page, size) {
    let url = base + "/deviceAlert/byUserId?userId=" + userid
    let params = new URLSearchParams();
    if (isFinish !== undefined && isFinish !== null) {
        params.set("isFinish", isFinish);
    }
    if (read !== undefined && read !== null) {
        params.set("read", read);
    }
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + params.toString();
    return axios.get(url).then(response => {
        return response.data
    })
}

export const alarm_service = {
    obtainDeviceAlarmsCount, obtainDeviceAlarms, obtainDeviceAlarmsByMac, obtainDeviceAlarmsByUserid
}
