import React, {Component} from 'react'
import {
    DialogTitle,
    Button,
    DialogContent,
    DialogActions,
    Dialog,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@material-ui/core';
import {customer_service} from "../../service/customer";
import {obtainCustomer} from "../../action";
import connect from "react-redux/es/connect/connect";

function mapStateToProps(state) {
    return {
        dataList: state.customerReducer.customers
    }
}

const mapDispatchToProps = {
    obtainCustomer
}

class UserAddComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            adjust: false,
            data: '',
            customerProxyList: []
        }

    }

    componentDidMount() {
        let customerProxyList = []
        let json = {}
        json['id'] = this.props.data.customerProxyId
        json['name'] = this.props.data.customerProxyName
        customerProxyList.push(json)
        this.setState({
            data: this.props.data,
            customerProxyList: customerProxyList
        })
        customer_service.obtainCustomer(undefined, undefined, 0, 9999).then(response => {
            response = response.data;
            this.props.obtainCustomer({
                size: response.size,
                page: response.page,
                upPage: response.upPage,
                nextPage: response.nextPage,
                totalCount: response.totalCount,
                totalPage: response.totalPage,
                firstResult: response.firstResult,
                lastResult: response.lastResult,
                customers: response.datas
            })
        })
    }

    getCustomProxy(id) {
        customer_service.obtainCustomerByProxy(id, null, null, null).then(response => {
            this.setState({
                customerProxyList: response.data.datas
            })
        })
    }

    render() {

        let {adjust, data, customerProxyList} = this.state;
        // console.log("user info: ")
        // console.log(data)
        return (
            <span className={this.props.className}>
                <a style={{color: 'green'}} onClick={() => {
                    this.setState({
                        adjust: true
                    })
                }}>修改</a>
                {data != "" &&
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    // onEntering={handleEntering}
                    aria-labelledby="confirmation-dialog-title"
                    open={adjust}
                >
                    <DialogTitle id="confirmation-dialog-title">修改用户信息</DialogTitle>
                    <DialogContent dividers>
                        <div className="adjust-row">
                            <TextField className="adjust-one" id="standard-basic" label="系统账号" value={data.id}
                                       disabled={true}/>
                            <FormControl className="adjust-one">
                                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                    所属角色
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.roleId}
                                    onChange={(e) => {
                                        data['roleId'] = e.target.value
                                        this.setState({
                                            data: data
                                        })
                                    }}
                                >
                                    <MenuItem value="596ad1f48c313f0b9a00be4c">管理员</MenuItem>
                                    <MenuItem value="597a9e8e8c313f7ca06344ca">一般用户</MenuItem>
                                    <MenuItem value="5cb95f9aa7fdde1872709e7d">单位管理员</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                        <div className="adjust-row">
                            <TextField className="adjust-one" id="standard-basic" label="真实姓名" value={data.name}
                                       onChange={(e) => {
                                           data['name'] = e.target.value
                                           this.setState({
                                               data: data
                                           })
                                       }}/>
                            <TextField className="adjust-one" id="standard-basic" label="手机号码" value={data.mobile}
                                       onChange={(e) => {
                                           data['mobile'] = e.target.value
                                           this.setState({
                                               data: data
                                           })
                                       }}/>
                        </div>
                        <div className="adjust-row">
                            <FormControl className="adjust-one">
                                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                    所属客户
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.customerId}
                                    onChange={(e) => {
                                        data['customerId'] = e.target.value
                                        data['customerProxyId'] = null
                                        this.getCustomProxy(e.target.value)
                                        this.setState({
                                            data: data
                                        })
                                    }}
                                >
                                    {this.props.dataList.map((item, index) => {
                                        return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className="adjust-one">
                                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                    所属部门
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.customerProxyId}
                                    onChange={(e) => {
                                        data['customerProxyId'] = e.target.value
                                        this.setState({
                                            data: data
                                        })
                                    }}
                                >
                                    {customerProxyList.map((item, index) => {
                                        return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => {
                            this.setState({
                                adjust: false
                            })
                        }}>
                            取消
                        </Button>
                        <Button onClick={() => {
                            this.setState({
                                adjust: false
                            })
                            this.props.onChangeData(data)
                        }} color="primary">
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>
                }

            </span>
        );
    }

}

const UserAdd = connect(mapStateToProps, mapDispatchToProps)(UserAddComponent)

export default UserAdd;
