import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
// import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
// import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'
// Containers
import Full from './containers/DefaultLayout/DefaultLayout.js';
import UserDashboard from "./userViews/Dashboard/dashboard";
// import '../node_modules/@coreui/styles/scss/_dropdown-menu-right.scss';


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const Login = React.lazy(() => import('./views/Pages/Login'));

// import { renderRoutes } from 'react-router-config';

class App extends Component {
    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading()}>
                    <Switch>
                        <Route exact path='/login' name='Login' render={props => <Login {...props}/>}/>
                        <Route exact path='/dashboard' name='Dashboard' render={props => <Full {...props}/>}/>
                        <Route path='/userDashboard' name='UserDashboard' render={props => <UserDashboard {...props}/>}/>
                        <Route path="/" name="Home" component={Full}/>
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

export default App;
