import React from 'react'
import {TextField} from '@material-ui/core';
import {Badge, Button, Col, Form, FormControl, InputGroup, Modal} from 'react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TimePicker from 'react-time-picker'
import {connect} from 'react-redux'
import {machine_service} from "../../service/machine";

import {obtainTimingConfig, obtainControlConfig} from "../../action"

function mapStateToProps(state) {
    return {
        mode: state.statusReducer.mode,
        h_time: state.timingReducer.h_time,
        m_time: state.timingReducer.m_time
    }
}

const mapDispatchToProps = {
    obtainTimingConfig, obtainControlConfig
}

class TimingModeComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal_show: false,
            action_name: 'cancel',
            h_time: 0,
            m_time: 0
        }
        this.obtain_timing_config.bind(this);
    }

    componentDidMount() {
        if (this.props.mac !== undefined && this.props.mac !== "") {
            this.obtain_timing_config(this.props.mac);
            this.setState({mac: this.props.mac})
        }
    }

    componentWillReceiveProps(next) {
        if (next.mac !== "" && next.mac != this.props.mac) {
            this.obtain_timing_config(next.mac);
        }
    }

    obtain_timing_config(mac) {
        machine_service.obtainTimingStatus(mac).then(response => {
            response = response.data;
            let h_time = response.hourTime;
            let m_time = response.minTime;
            let h_control_1s = response.time1_1Mode;
            let m_control_1s = response.time1_2Mode;
            let h_control_1e = response.time1_3Mode;
            let m_control_1e = response.time1_4Mode;
            let h_control_2s = response.time2_1Mode;
            let m_control_2s = response.time2_2Mode;
            let h_control_2e = response.time2_3Mode;
            let m_control_2e = response.time2_4Mode;
            let h_control_3s = response.time3_1Mode;
            let m_control_3s = response.time3_2Mode;
            let h_control_3e = response.time3_3Mode;
            let m_control_3e = response.time3_4Mode;
            let h_control_4s = response.time4_1Mode;
            let m_control_4s = response.time4_2Mode;
            let h_control_4e = response.time4_3Mode;
            let m_control_4e = response.time4_4Mode;
            let h_control_5s = response.time5_1Mode;
            let m_control_5s = response.time5_2Mode;
            let h_control_5e = response.time5_3Mode;
            let m_control_5e = response.time5_4Mode;
            let h_control_6s = response.time6_1Mode;
            let m_control_6s = response.time6_2Mode;
            let h_control_6e = response.time6_3Mode;
            let m_control_6e = response.time6_4Mode;
            let control_1_enabled = response.timeseg1;
            let control_2_enabled = response.timeseg2;
            let control_3_enabled = response.timeseg3;
            let control_4_enabled = response.timeseg4;
            let control_5_enabled = response.timeseg5;
            let control_6_enabled = response.timeseg6;
            this.props.obtainTimingConfig({
                h_time: h_time,
                m_time: m_time
            })
            this.props.obtainControlConfig({
                h_control_1s: h_control_1s,
                m_control_1s: m_control_1s,
                h_control_1e: h_control_1e,
                m_control_1e: m_control_1e,
                h_control_2s: h_control_2s,
                m_control_2s: m_control_2s,
                h_control_2e: h_control_2e,
                m_control_2e: m_control_2e,
                h_control_3s: h_control_3s,
                m_control_3s: m_control_3s,
                h_control_3e: h_control_3e,
                m_control_3e: m_control_3e,
                h_control_4s: h_control_4s,
                m_control_4s: m_control_4s,
                h_control_4e: h_control_4e,
                m_control_4e: m_control_4e,
                h_control_5s: h_control_5s,
                m_control_5s: m_control_5s,
                h_control_5e: h_control_5e,
                m_control_5e: m_control_5e,
                h_control_6s: h_control_6s,
                m_control_6s: m_control_6s,
                h_control_6e: h_control_6e,
                m_control_6e: m_control_6e,
                control_1_enabled: control_1_enabled,
                control_2_enabled: control_2_enabled,
                control_3_enabled: control_3_enabled,
                control_4_enabled: control_4_enabled,
                control_5_enabled: control_5_enabled,
                control_6_enabled: control_6_enabled,
            })
        })
    }

    timing(h_time, m_time) {
        this.props.send_message("/client/XD/" + this.props.mac + "/set_timer", JSON.stringify({
            "h":parseInt(h_time),
            "m":parseInt(m_time)
        }))
    }

    hide = () => {
        this.setState({
            modal_show: false
        })
    }

    show = () => {
        this.setState({
            modal_show: true,
            action_name: 'cancel'
        })
    }

    set = () => {
        this.setState({
            modal_show: true,
            action_name: 'config'
        })
    }

    confirm_cancel = () => {
        this.hide();
        this.timing(0, 0)
    }

    confirm_config = () => {
        this.hide();
        this.timing(this.state.h_time, this.state.m_time)
    }

    read_hour = (e) => {
        let value = e.target.value;
        if (value == "" || (value <= 23 && value >= 0)) {
            this.setState({h_time: value});
        } else {
            return;
        }
    }


    read_minute = (e) => {
        let value = e.target.value;
        if (value == "" || (value <= 59 && value >= 0)) {
            this.setState({m_time: value});
        } else {
            return;
        }
    }

    render() {

        if (this.props.isOnline === true) {
            return (
                <div className="time-top">
                    <div className="time-top-left">定时模式：</div>
                    <div className="time-top-right">
                        {(this.props.mode === 2) &&
                        <Form.Row>
                            <Col xs="mb-6">
                                <div>{this.props.h_time}小时{this.props.m_time}分钟</div>
                                &nbsp;
                            </Col>
                            <Col xs="auto">
                                (生效中)
                                <a className="link" onClick={this.show}>取消</a>
                            </Col>
                        </Form.Row>
                        }
                        {(this.props.mode !== 2) &&
                        <Form.Row>
                            <Col xs="mb-6">
                                <div>(未生效)</div>
                                &nbsp;
                            </Col>
                            <Col xs="auto">

                                <a className="link" onClick={this.set}>设置</a>
                            </Col>
                        </Form.Row>

                        }
                    </div>
                    <Modal
                        show={this.state.modal_show}
                        onHide={this.hide}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.action_name === 'config' ? "设置设定（不超过23小时59分钟）" : "关闭定时"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputGroup className="mb-3">
                                {this.state.action_name == "cancel" &&
                                <p>确认关闭定时功能？</p>
                                }
                                {this.state.action_name == "config" &&
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>运行时间</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl onChange={this.read_hour} value={this.state.h_time}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text>小时</InputGroup.Text>
                                    </InputGroup.Append>
                                    <FormControl onChange={this.read_minute} value={this.state.m_time}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text>分钟</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                }
                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.hide}>
                                取消
                            </Button>
                            {this.state.action_name === 'cancel' &&
                            <Button variant="primary" onClick={this.confirm_cancel}>确认</Button>
                            }
                            {this.state.action_name === 'config' &&
                            <Button variant="primary" onClick={this.confirm_config}>确认</Button>
                            }
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        } else {
            return (
                <div className="time-top">
                    <div className="time-top-left">定时模式：</div>
                    <div className="time-top-right">

                    </div>
                </div>
            )
        }


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimingModeComponent)
