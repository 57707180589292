import {OBTAIN_SYS_LOG} from "../constant/action-type";

const initialState = {
    size: 0,
    page: 0,
    upPage: 0,
    nextPage: 0,
    totalCount: 0,
    totalPage: 0,
    logs: [
        {
            "id": "string",
            "name": "string",
            "userName": "string",
            "deviceName": "string",
            "mac": "string",
            "operation": "string",
            "remarks": "string",
            "createTime": "2020-04-18T06:50:58.689Z",
            "keys": "string"
        }
    ],
    firstResult: 0,
    lastResult: 0
}

function logReducer(state = initialState, action) {
    if (action.type === OBTAIN_SYS_LOG) {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default logReducer;