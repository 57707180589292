import axios from 'axios';
import {util_service} from "./util";

axios.defaults.withCredentials = true

let createHistory = require("history").createHashHistory;

axios.defaults.headers = {
    "Content-Type": "application/x-www-form-urlencoded"
}

axios.interceptors.response.use((response) => {
    return response
}, (err) => {

    if (err.response.status == '401') {
        // const history = createHistory();
        // history.push('/login')
    }
    return Promise.reject(err)
})

let base = "http://47.103.193.179:9090/Juguang";

//获取客户列表，其中page, size为分页参数
function obtainCustomer(key, isProxy, page, size) {
    let url = base + "/customer";
    let params = new URLSearchParams();
    if (key !== undefined && key !== null) {
        params.set("key", key);
    }
    if (isProxy !== undefined && isProxy !== null) {
        params.set("isProxy", isProxy);
    }
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + "?" + params.toString();
    return axios.get(url).then(response => {
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(response)
            util_service.clearCredential();
            window.location.href = "/";
        }
    })
}

//获取某一个机构{id}下的用户信息列表
function obtainCustomerByProxy(id, key, page, size) {
    let url = base + "/customer/byProxy?id=" + id;
    let params = new URLSearchParams();
    if (key !== undefined && key !== null) {
        params.set("key", key);
    }
    if (page !== undefined && page !== null) {
        params.set("page", page);
    }
    if (size !== undefined && size !== null) {
        params.set("size", size);
    }
    url = url + params.toString();
    return axios.get(url).then(response => {
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(response)
            util_service.clearCredential();
            window.location.href = "/";
        }
    })
}

//获取当前用户所处的机构
function obtainProxy() {
    let url = base + "/customer/proxy";
    return axios.get(url).then(response => {
        return response.data.data;
    })
}

function createCustomer(name, contact, contactTel, address, isProxy, proxy, proxyName, outChain, storeAddress, remarks, imageUrl, productUrl, keys, logoUrl, afterPhone) {
    let url = base + "/customer";
    let form = new FormData();
    form.append("name", name);
    form.append("contact", contact);
    form.append("contactTel", contactTel);
    form.append("address", address);
    form.append("isProxy", isProxy);
    form.append("proxy", proxy);
    form.append("proxyName", proxyName);
    form.append("outChain", outChain);
    form.append("storeAddress", storeAddress);
    form.append("remarks", remarks);
    form.append("imageUrl", imageUrl);
    form.append("productUrl", productUrl);
    form.append("keys", keys);
    form.append("logoUrl", logoUrl);
    form.append("afterPhone", afterPhone);
    return axios.post(url, form).then(response => {
        return response.data;
    })
}

export const customer_service = {
    obtainCustomer, createCustomer, obtainCustomerByProxy, obtainProxy
}
