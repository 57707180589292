import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "react-table/react-table.css";
import App from './App';
import {Provider} from "react-redux";
import store from "./store/index";
import * as serviceWorker from './serviceWorker';
import MomentUtils from "@date-io/moment";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "material-ui-thai-datepickers";

ReactDOM.render(<Provider store={store}><MuiPickersUtilsProvider utils={DateFnsUtils} ><App/></MuiPickersUtilsProvider></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
