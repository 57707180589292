import React, {Component} from 'react';
import moment from 'moment';
import {ListGroup} from 'react-bootstrap';
import {connect} from "react-redux";

import '../../scss/custom.scss'

import {location_service} from "../../service/location";
import {user_service} from "../../service/user";

import {updateDate, updateTime, obtainLocation} from "../../action";

function mapStateToProps(state) {
    return {
        userId: state.userReducer.id,
        corpName: state.corpInfoReducer.corpName,
        corpTel: state.corpInfoReducer.corpTel,
        time: state.corpInfoReducer.time,
        date: state.corpInfoReducer.date,
        provinceName: state.userInfoReducer.provinceName,
        cityName: state.userInfoReducer.cityName,
        location: state.userInfoReducer.location,
        district: state.locationReducer.district
    }
}

const mapDispatchToProps = {
    updateDate, updateTime, obtainLocation
}

class ClockComponent extends Component {

    componentDidMount() {
        this.getPosition();
        setInterval(function () {
            let time = moment().format("HH:mm");
            let date = moment().format("YYYY.MM.DD");
            this.props.updateTime({time: time});
            this.props.updateDate({date: date});
        }.bind(this), 5000)
    }

    getPosition() {
        let that = this;
        let userId = localStorage.getItem("id");
        if (userId !== undefined) {
            user_service.locate(userId).then(response => {
                if (response.responseCode === "RESPONSE_OK") {
                    // console.log(JSON.stringify(response))
                    let code = response.data[0].addrCode;
                    that.props.obtainLocation({
                        district: response.data[0].districtName,
                        city_name: response.data[0].cityName,
                        city_id: code
                    })
                } else {
                    let BMap = window.BMap;//取出window中的BMap对象
                    console.log("get location")
                    navigator.geolocation.getCurrentPosition(function (position) {
                        // console.log("Latitude is :", position.coords.latitude);
                        // console.log("Longitude is :", position.coords.longitude);
                        let point = new BMap.Point(position.coords.longitude, position.coords.latitude);
                        let convertor = new BMap.Convertor();
                        let pointArr = [];
                        pointArr.push(point);
                        convertor.translate(pointArr, 3, 5, data => {
                            if (data.status === 0) {
                                let pt = data.points[0];
                                let geoc = new BMap.Geocoder();
                                geoc.getLocation(pt, rs => {
                                    let address = rs.addressComponents;
                                    if (address.city != undefined && address.city != "") {
                                        // location_service.obtainCityByName(address.city).then(response => {
                                        //     this.props.obtainLocation({
                                        //         district: address.district,
                                        //         city_id: response.id,
                                        //         city_name: response.name,
                                        //         city_code: response.code,
                                        //         province: response.province,
                                        //         area_code: response.area_code
                                        //     })
                                        // })
                                        let desc = "";
                                        if (address.province !== undefined) {
                                            desc += address.province;
                                        }
                                        if (address.city !== undefined) {
                                            desc += address.city;
                                        }
                                        if (address.district !== undefined) {
                                            desc += address.district;
                                        }
                                        location_service.obtainGMLocation(desc).then(response => {
                                            response = response.data;
                                            let ad_info = response.ad_info;
                                            let ad_code = ad_info.adcode;
                                            that.props.obtainLocation({
                                                district: address.district,
                                                city_name: address.city,
                                                city_id: ad_code
                                            })
                                        })
                                    }

                                });
                            }
                        });
                    }, function () {
                        let geolocation = new BMap.Geolocation();
                        geolocation.getCurrentPosition(r => {
                            let geoc = new BMap.Geocoder();
                            let point = new BMap.Point(r.point.lng, r.point.lat);
                            // 坐标转换，谷歌坐标转百度坐标
                            let convertor = new BMap.Convertor();
                            let pointArr = [];
                            pointArr.push(point);
                            convertor.translate(pointArr, 3, 5, data => {
                                if (data.status === 0) {
                                    let pt = data.points[0];
                                    geoc.getLocation(pt, rs => {
                                        let address = rs.addressComponents;
                                        if (address.city != undefined && address.city != "") {
                                            // location_service.obtainCityByName(address.city).then(response => {
                                            //     this.props.obtainLocation({
                                            //         district: address.district,
                                            //         city_id: response.id,
                                            //         city_name: response.name,
                                            //         city_code: response.code,
                                            //         province: response.province,
                                            //         area_code: response.area_code
                                            //     })
                                            // })
                                            let desc = "";
                                            if (address.province !== undefined) {
                                                desc += address.province;
                                            }
                                            if (address.city !== undefined) {
                                                desc += address.city;
                                            }
                                            if (address.district !== undefined) {
                                                desc += address.district;
                                            }
                                            location_service.obtainGMLocation(desc).then(response => {
                                                response = response.data;
                                                let ad_info = response.ad_info;
                                                let ad_code = ad_info.adcode;
                                                that.props.obtainLocation({
                                                    district: address.district,
                                                    city_name: address.city,
                                                    city_id: ad_code
                                                })
                                            })
                                        }

                                    });
                                }
                            });
                        });
                    });
                }
            })
        }
    }

    render() {
        return (
            <div>
                <div>
                    <ListGroup className='clock-time'>
                        {this.props.time}
                    </ListGroup>
                    <ListGroup className='clock-date'>
                        {this.props.date}
                    </ListGroup>
                    <ListGroup className='location'>
                        <div>
                            <i className="cil-location-pin"></i> &nbsp;
                            <span>{this.props.district}</span>
                        </div>
                    </ListGroup>
                    <ListGroup className='footer'>
                        <div className='corp-name'>{this.props.corpName}</div>
                        <div className='corp-tel'>{this.props.corpTel}</div>
                    </ListGroup>
                </div>
            </div>
        )
    }
}

const Clock = connect(mapStateToProps, mapDispatchToProps)(ClockComponent);

export default Clock;
