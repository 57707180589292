import axios from 'axios'

axios.defaults.withCredentials = true

let createHistory = require("history").createHashHistory;

let API_BASE = "http://47.103.193.179:9090/Juguang";

const instance = axios.create({
    withCredentials: true,
    baseURL: API_BASE
})

instance.defaults.headers = {
    "Content-Type": "application/x-www-form-urlencoded"
}

instance.interceptors.response.use((response) => {
    return response
}, (err) => {
    console.error(err)
    // const history = createHistory();
    // history.push('/login')
    return Promise.reject(err)
})

export default instance