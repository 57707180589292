import React, {Component} from 'react';
import '../../scss/userCustom.scss';
import {CardHeader, IconButton} from '@material-ui/core'
import routes from "../userRouters";
import {Redirect, Route, Switch} from "react-router-dom";

import {connect} from 'react-redux';

import {obtainCurrentAirQuality, obtainLocation, updateDate, updateTime} from "../../action";
import moment from "moment/moment";
import {location_service} from "../../service/location";
import {airquality_service} from "../../service/airquality";
import {user_service} from "../../service/user";

function mapStateToProps(state) {
    return {
        corpName: state.corpInfoReducer.corpName,
        corpTel: state.corpInfoReducer.corpTel,
        time: state.corpInfoReducer.time,
        date: state.corpInfoReducer.date,
        district: state.locationReducer.district,

        cityid: state.locationReducer.city_id,

        pm2_5: state.airReducer.pm2_5,
        aqi: state.airReducer.aqi,
        aqiLevel: state.airReducer.aqiLevel,
        pm10: state.airReducer.pm10,

        devices: state.machineReducer.devices
    }
}

const mapDispatchToProps = {
    updateTime,
    updateDate,
    obtainLocation,
    obtainCurrentAirQuality
}

class UserDashboardComponent extends Component {

    constructor() {
        super();
    }

    componentDidMount() {
        this.getPosition();
        setInterval(function () {
            let time = moment().format("HH:mm");
            let date = moment().format("YYYY.MM.DD");
            this.props.updateTime({time: time});
            this.props.updateDate({date: date});
        }.bind(this), 1000);
        if (this.props.cityid !== undefined && this.props.cityid !== "") {
            airquality_service.obtainGMOutPM25ById(this.props.cityid).then(response => {
                // response = response.data;
                // if (response === null) {
                //     console.log("当前城市无PM2.5的数据")
                // } else {
                //
                // }
                response = response.data[0];
                this.props.obtainCurrentAirQuality({
                    aqi: response.aqi,
                    aqiLevel: response.aqiLevel,
                    co: response.co,
                    no2: response.no2,
                    o3: response.o3,
                    pm2_5: response.pm2_5,
                    pm10: response.pm10,
                    so2: response.so2
                })

            })
        }
    }

    componentWillReceiveProps(next) {
        if (next.cityid !== this.props.cityid) {
            airquality_service.obtainGMOutPM25ById(next.cityid).then(response => {
                // response = response.data;
                // if (response === null) {
                //     console.log("当前城市无PM2.5的数据")
                // } else {
                //
                // }
                response = response.data[0];
                this.props.obtainCurrentAirQuality({
                    aqi: response.aqi,
                    aqiLevel: response.aqiLevel,
                    co: response.co,
                    no2: response.no2,
                    o3: response.o3,
                    pm2_5: response.pm2_5,
                    pm10: response.pm10,
                    so2: response.so2
                })

            })
        }
    }

    getPosition() {
        let that = this;
        let userId = localStorage.getItem("id");
        if (userId !== undefined) {
            user_service.locate(userId).then(response => {
                if (response.responseCode === "RESPONSE_OK") {
                    let code = response.data[0].addrCode;
                    that.props.obtainLocation({
                        district: response.data[0].districtName,
                        city_name: response.data[0].cityName,
                        city_id: code
                    })
                } else {
                    let BMap = window.BMap;//取出window中的BMap对象
                    console.log("get location")
                    navigator.geolocation.getCurrentPosition(function (position) {
                        console.log("Latitude is :", position.coords.latitude);
                        console.log("Longitude is :", position.coords.longitude);
                        let point = new BMap.Point(position.coords.longitude, position.coords.latitude);
                        let convertor = new BMap.Convertor();
                        let pointArr = [];
                        pointArr.push(point);
                        convertor.translate(pointArr, 3, 5, data => {
                            if (data.status === 0) {
                                let pt = data.points[0];
                                let geoc = new BMap.Geocoder();
                                geoc.getLocation(pt, rs => {
                                    let address = rs.addressComponents;
                                    if (address.city != undefined && address.city != "") {
                                        // location_service.obtainCityByName(address.city).then(response => {
                                        //     this.props.obtainLocation({
                                        //         district: address.district,
                                        //         city_id: response.id,
                                        //         city_name: response.name,
                                        //         city_code: response.code,
                                        //         province: response.province,
                                        //         area_code: response.area_code
                                        //     })
                                        // })
                                        let desc = "";
                                        if (address.province !== undefined) {
                                            desc += address.province;
                                        }
                                        if (address.city !== undefined) {
                                            desc += address.city;
                                        }
                                        if (address.district !== undefined) {
                                            desc += address.district;
                                        }
                                        location_service.obtainGMLocation(desc).then(response => {
                                            response = response.data;
                                            let ad_info = response.ad_info;
                                            let ad_code = ad_info.adcode;
                                            that.props.obtainLocation({
                                                district: address.district,
                                                city_name: address.city,
                                                city_id: ad_code
                                            })
                                        })
                                    }

                                });
                            }
                        });
                    }, function () {
                        let geolocation = new BMap.Geolocation();
                        geolocation.getCurrentPosition(r => {
                            let geoc = new BMap.Geocoder();
                            let point = new BMap.Point(r.point.lng, r.point.lat);
                            // 坐标转换，谷歌坐标转百度坐标
                            let convertor = new BMap.Convertor();
                            let pointArr = [];
                            pointArr.push(point);
                            convertor.translate(pointArr, 3, 5, data => {
                                if (data.status === 0) {
                                    let pt = data.points[0];
                                    geoc.getLocation(pt, rs => {
                                        let address = rs.addressComponents;
                                        if (address.city != undefined && address.city != "") {
                                            // location_service.obtainCityByName(address.city).then(response => {
                                            //     this.props.obtainLocation({
                                            //         district: address.district,
                                            //         city_id: response.id,
                                            //         city_name: response.name,
                                            //         city_code: response.code,
                                            //         province: response.province,
                                            //         area_code: response.area_code
                                            //     })
                                            // })
                                            let desc = "";
                                            if (address.province !== undefined) {
                                                desc += address.province;
                                            }
                                            if (address.city !== undefined) {
                                                desc += address.city;
                                            }
                                            if (address.district !== undefined) {
                                                desc += address.district;
                                            }
                                            location_service.obtainGMLocation(desc).then(response => {
                                                response = response.data;
                                                let ad_info = response.ad_info;
                                                let ad_code = ad_info.adcode;
                                                that.props.obtainLocation({
                                                    district: address.district,
                                                    city_name: address.city,
                                                    city_id: ad_code
                                                })
                                            })
                                        }

                                    });
                                }
                            });
                        });
                    });
                }
            })
        }
    }

    render() {

        return (
            <div className="user-dashboard-panel">
                <div className="user-sider">
                    <div className="sider-1">
                        <CardHeader
                            avatar={
                                <i className="cil-sun"></i>
                            }
                            action={
                                <IconButton aria-label="settings">

                                </IconButton>
                            }
                            title="今日"
                            subheader={this.props.date}
                        />
                    </div>
                    {/*<div className="sider-2">*/}
                        {/**/}
                    {/*</div>*/}
                    {/*<div className="sider-3">*/}
                        {/*<div><span className="sider-3-temp">28</span><sup>℃</sup></div>*/}
                        {/*<div className="sider-3-title">室外空气指数</div>*/}
                        {/*<div className="sider-3-value"><span className="sider-3-value-value">{this.props.aqi}</span>*/}
                        {/*</div>*/}
                        {/*<div className="sider-3-title">室外PM2.5</div>*/}
                        {/*<div className="sider-3-value"><span className="sider-3-value-value">{this.props.pm2_5}</span>μg/m³*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <div className="sider-4">
                        <div className="sider-2-time">{this.props.time}</div>
                        <div className="sider-2-city">{this.props.district}</div>
                        <div className="sider-corpname">{this.props.corpName}</div>
                        <div className="sider-corptel">{this.props.corpTel}</div>
                    </div>
                </div>
                <div className="user-content">
                    <Switch>
                        {routes.map((route, idx) => {
                                return route.component ? (
                                        <Route key={idx} path={route.path} exact={route.exact} name={route.name}
                                               render={props => (
                                                   <route.component {...props} />
                                               )}/>)
                                    : (null);
                            },
                        )}
                        <Redirect from="/userDashboard" to="/userDashboard/show"/>
                    </Switch>
                </div>
            </div>
        );
    }

}

const UserDashboard = connect(mapStateToProps, mapDispatchToProps)(UserDashboardComponent);

export default UserDashboard;
