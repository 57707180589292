import {OBTAIN_USERS} from "../constant/action-type";

const initialState = {
    size: 0,
    page: 0,
    upPage: 0,
    nextPage: 0,
    totalCount: 0,
    totalPage: 0,
    firstResult: 0,
    lastResult: 0,
    datas: [
        {
            "id": "string",
            "name": "string",
            "username": "string",
            "mobile": "string",
            "address": "string",
            "email": "string",
            "loginDate": "2020-04-17T09:38:30.494Z",
            "loginIp": "string",
            "createTime": "2020-04-17T09:38:30.494Z",
            "locked": true,
            "isAdmin": true,
            "isUser": true,
            "isCustomer": true,
            "image": "string",
            "role": "string",
            "roleName": "string",
            "roleId": "string",
            "customerId": "string",
            "customerName": "string",
            "customerProxyId": "string",
            "customerProxyName": "string",
            "provinceId": "string",
            "provinceName": "string",
            "cityId": "string",
            "districtId": "string",
            "cityName": "string",
            "districtName": "string",
            "deviceCount": 0,
            "publicNumberId": "string",
            "appId": "string",
            "houseArea": 0,
            "openid": "string",
            "mac": "string",
            "deviceAlertCount": 0,
            "remark": "string",
            "permissions": [
                "string"
            ],
            "source": "string",
            "admin": true
        }
    ]
}

function userReducer(state=initialState, action) {
    if (action.type === OBTAIN_USERS) {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default userReducer;