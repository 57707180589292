import Dashboard from './views/Dashboard/Dashboard.js';
import {Login} from './views/Pages/index'
import Client from "./views/Client/Client";
import User from "./views/User/user";
import Device from "./views/Device/device";
import Alarm from "./views/Alarm/alarm";
import DeviceDetail from "./views/Device/deviceDetail";
import SysLog from "./views/Log/sysLog";


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    {path: '/login', exact: true, name: 'Login', component: Login},
    {path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard},
    {path: '/customer', exact: true, name: 'Client', component: Client},
    {path: '/user', exact: true, name: 'User', component: User},
    {path: '/device', exact: true, name: 'Device', component: Device},
    {path: '/warning', exact: true, name: 'Alarm', component: Alarm},
    {path: '/device/detail/:deviceId', exact: true, name: 'DeviceDetail', component: DeviceDetail},
    {path: '/device/syslog', exact: true, name: 'SysLog', component: SysLog},
];

export default routes;
