import {OBTAIN_CORP_INFO, UPDATE_TIME, UPDATE_DATE} from '../constant/action-type'

const initialState = {
    corpName: "江苏巨光光电科技有限公司",
    corpTel: "0510-86631181",
    time: '',
    date: ''
}

function corpInfoReducer(state = initialState, action) {
    if (action.type === OBTAIN_CORP_INFO) {
        return Object.assign({}, state, action.payload)
    }
    if (action.type === UPDATE_TIME) {
        return Object.assign({}, state, action.payload)
    }
    if (action.type === UPDATE_DATE) {
        return Object.assign({}, state, action.payload)
    }
    return state;
};

export default corpInfoReducer;