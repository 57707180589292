import React, {Component} from 'react';
import {datetimeService} from "../../service/datetime";
import {alarm_service} from "../../service/alarm";
import {Timeline, TimelineEvent} from "react-event-timeline";
import {Card} from "react-bootstrap";
import {obtainDeviceAlarms} from "../../action";
import connect from "react-redux/es/connect/connect";
import {machine_service} from "../../service/machine";

function mapStateToProps(state) {
    return {
        dataList: state.alarmReducer.dataList
    }
}

const mapDispatchToProps = {
    obtainDeviceAlarms
}

class AlarmOne extends Component {
    constructor(props) {
        super(props);
        this.state={
            name:''
        }
        this.obtain_alarm = this.obtain_alarm.bind(this)
    }

    componentDidMount() {
        console.log(this.props)
        if (this.props.mac !== undefined && this.props.mac !== "") {
            this.obtain_alarm(this.props.mac)
        }
    }

    componentWillReceiveProps(next) {
        if (next.mac !== "" && next.mac != this.props.mac) {
            this.obtain_alarm(next.mac)
        }
    }

    obtain_alarm(mac) {
        alarm_service.obtainDeviceAlarmsByMac(mac).then(response => {
            let data = response.data;
            this.props.obtainDeviceAlarms({
                size: data.size,
                page: data.page,
                upPage: data.upPage,
                nextPage: data.nextPage,
                totalCount: data.totalCount,
                totalPage: data.totalPage,
                firstResult: data.firstResult,
                lastResult: data.lastResult,
                dataList: data.datas
            })
        })
        machine_service.profile(this.props.id).then(response=>{
            this.setState({
                name:response.data.deviceTypeName
            })
        })
    }

    render() {
        return (
            <Card className="log-card">
                <Card.Header>警报信息</Card.Header>
                <Card.Body className="log-card-body">
                    <div className="log-detail">
                        {this.props.dataList.length > 0 && this.props.dataList.map((item, index) => {
                            return (<div className="log-detail-item" key={item.id}>
                                <div className="log-detail-item-left">
                                    <i className="cil-envelope-closed"></i>&nbsp;{item.customerName}({item.mac})——{this.state.name}, {item.alertName}
                                </div>
                                <div className="log-detail-item-right">
                                    {datetimeService.formatTimeStampToDateTime(item.createTime)}
                                </div>
                            </div>)
                        })}
                    </div>
                </Card.Body>
            </Card>
        );
    }

}

const Alarm = connect(mapStateToProps, mapDispatchToProps)(AlarmOne)

export default Alarm;
