import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import '../../scss/client.scss';

import {connect} from 'react-redux';
// Import React Table
import ReactTable from "react-table";

import {obtainCustomer} from "../../action";
import {customer_service} from "../../service/customer";
import {Checkbox} from "@material-ui/core";

function mapStateToProps(state) {
    return {
        dataList: state.customerReducer.customers,
        totalCount: state.customerReducer.totalCount,
    }
}

const mapDispatchToProps = {
    obtainCustomer
}

class ClientComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: null,
            isProxy: true,
            // page:0,
            pageSize: 10,
            pageSizeList: [10, 15, 20, 50],
            showModal: false
        }
    }

    obtainCustomer(pageSize) {
        customer_service.obtainCustomer(this.state.search, this.state.isProxy, 0, pageSize).then(response => {
            response = response.data;
            this.props.obtainCustomer({
                size: response.size,
                page: response.page,
                upPage: response.upPage,
                nextPage: response.nextPage,
                totalCount: response.totalCount,
                totalPage: response.totalPage,
                firstResult: response.firstResult,
                lastResult: response.lastResult,
                customers: response.datas
            })
        })
    }

    componentDidMount() {
        customer_service.obtainCustomer(this.state.search, this.state.isProxy, 0, this.state.pageSize).then(response => {
            response = response.data;
            this.props.obtainCustomer({
                size: response.size,
                page: response.page,
                upPage: response.upPage,
                nextPage: response.nextPage,
                totalCount: response.totalCount,
                totalPage: response.totalPage,
                firstResult: response.firstResult,
                lastResult: response.lastResult,
                customers: response.datas
            })
        })
    }

    showCustomerModal() {

    }

    render() {
        let that = this;
        let dataList = this.props.dataList;
        if (dataList.length > 0) {
            for (let i = 0; i < dataList.length; i++) {
                dataList[i]['num'] = i + 1
            }
        }
        let columns = [{
            Header: <Checkbox color="primary" disabled/>,
            width: 65,
            accessor: 'edit',
            Cell: <Checkbox color="primary"/>,
            sortable: false
        }, {
            Header: '序号',
            width: 65,
            accessor: 'num'
        }, {
            Header: '客户名称',
            accessor: 'name'
        }, {
            Header: '联系人',
            accessor: 'contact'
        }, {
            Header: '联系电话',
            accessor: 'contactTel'
        }, {
            Header: '客户地址',
            accessor: 'address',
        }, {
            Header: '操作',
            accessor: 'action',
            width: 200,
            Cell: row => (<div>
                <a className="cursor-style" style={{color: 'green'}} onClick={that.showCustomerModal}>修改</a>&nbsp;&nbsp;&nbsp;
                <a className="cursor-style" style={{color: 'orange'}}>部门</a>&nbsp;&nbsp;&nbsp;
                <a className="cursor-style" style={{color: 'blue'}}>详情</a>&nbsp;&nbsp;&nbsp;
                <a className="cursor-style" style={{color: 'red'}}>删除</a>
            </div>)
        }];

        return (
            <div className="client-panel">
                <div className="client-top">
                    <div className="client-bread">
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/#/dashboard">首页</a></BreadcrumbItem>
                            <BreadcrumbItem active>客户管理</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="client-search">
                        <InputGroup className="client-search-input">
                            <Input placeholder="请输入搜索内容" onChange={(e) => {
                                this.setState({
                                    search: e.target.value
                                })
                            }}/>
                            <InputGroupAddon addonType="append">
                                <Button color="secondary" onClick={() => {
                                    this.obtainCustomer(this.props.totalCount)
                                }}>搜索</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
                <div className="table-panel">
                    <ReactTable
                        data={dataList}
                        columns={columns}
                        defaultPageSize={this.state.pageSize}
                        className="-highlight table-row"
                        pageSizeOptions={this.state.pageSizeList}
                        previousText={"上一页"}
                        nextText={"下一页"}
                        noDataText={"没有数据"}
                        pageText={"当前页"}
                        ofText={"总页数"}
                        rowsText={"条/页"}
                    />
                </div>
                <Modal isOpen={this.state.showModal}>
                    <ModalHeader>Modal title</ModalHeader>
                    <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

const Client = connect(mapStateToProps, mapDispatchToProps)(ClientComponent)

export default Client;
