import {
    FILL_USERNAME, FILL_PASSWORD, GEN_CAPTCHA, FILL_CODE, GEN_ALERT_MSG,
    SHOW_ALERT, HIDE_ALERT
} from "../constant/action-type";

const initialState = {
    username: "",
    password: "",
    captcha: "",
    code: "",
    alert_visible: false,
    alert_msg: ""
}

function credentialReducer(state = initialState, action) {
    if (action.type === FILL_USERNAME) {
        return Object.assign({}, state, action.payload)
    }
    if (action.type === FILL_PASSWORD) {
        return Object.assign({}, state, action.payload)
    }
    if (action.type === GEN_CAPTCHA) {
        return Object.assign({}, state, action.payload)
    }
    if (action.type === FILL_CODE) {
        return Object.assign({}, state, action.payload)
    }
    if (action.type === SHOW_ALERT) {
        return Object.assign({}, state, true)
    }
    if (action.type === HIDE_ALERT) {
        return Object.assign({}, state, false)
    }
    if (action.type === GEN_ALERT_MSG) {
        return Object.assign({}, state, action.payload)
    }
    return state;
}

export default credentialReducer;