import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import '../../scss/client.scss';
import { render } from "react-dom";

import { connect } from 'react-redux';

import { obtainUsers } from "../../action";
import { user_service } from "../../service/user";

// Import React Table
import ReactTable from "react-table";
import {
    Checkbox,
    Snackbar,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import { datetimeService } from "../../service/datetime";
import UserAdd from "./userAdd";
import { customer_service } from "../../service/customer";
import MyMessage from "./message";
import MyConfirm from "./confirm";

function mapStateToProps(state) {
    return {
        size: state.userReducer.size,
        page: state.userReducer.page,
        upPage: state.userReducer.upPage,
        nextPage: state.userReducer.nextPage,
        totalCount: state.userReducer.totalCount,
        totalPage: state.userReducer.totalPage,
        firstResult: state.userReducer.firstResult,
        lastResult: state.userReducer.lastResult,
        datas: state.userReducer.datas
    }
}

const mapDispatchToProps = {
    obtainUsers
}

class UserComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: null,
            isProxy: null,
            page: -1,
            pageSize: 10,
            pageSizeList: [10, 15, 20, 50],
            open: false,
            severity: 'success',
            message: '',
            confirm: false,
            content: '',
            data: []
        }
    }

    // obtainUser(pageSize) {
    //     user_service.obtainUsers(this.state.search, this.state.page, pageSize).then(response => {
    //         response = response.data;
    //         this.props.obtainUsers({
    //             size: response.size,
    //             page: response.page,
    //             upPage: response.upPage,
    //             nextPage: response.nextPage,
    //             totalCount: response.totalCount,
    //             totalPage: response.totalPage,
    //             firstResult: response.firstResult,
    //             lastResult: response.lastResult,
    //             datas: response.datas
    //         })
    //     })
    // }

    obtainNextUser() {
        // console.log("current page: " + this.state.page)
        user_service.obtainUsers(this.state.search, this.state.page + 1, this.state.pageSize).then(response => {
            response = response.data;
            this.props.obtainUsers({
                size: response.size,
                page: response.page,
                upPage: response.upPage,
                nextPage: response.nextPage,
                totalCount: response.totalCount,
                totalPage: response.totalPage,
                firstResult: response.firstResult,
                lastResult: response.lastResult,
                datas: response.datas
            })
            this.setState({
                page: response.page,
                size: response.size,
                totalCount: response.totalCount,
                totalPage: response.totalPage
            })
        })
    }


    componentDidMount() {
        this.obtainNextUser();
        // user_service.obtainUsers(this.state.search, 0, this.state.pageSize).then(response => {
        //     response = response.data;
        //     this.props.obtainUsers({
        //         size: response.size,
        //         page: response.page,
        //         upPage: response.upPage,
        //         nextPage: response.nextPage,
        //         totalCount: response.totalCount,
        //         totalPage: response.totalPage,
        //         firstResult: response.firstResult,
        //         lastResult: response.lastResult,
        //         datas: response.datas
        //     })
            // this.obtainUser(response.totalCount)
        // })
    }

    changeData(id, item, list) {
        let result = []
        for (let i = 0; i < list.length; i++) {
            if (list[i].id == id) {
                result.push(item)
            } else {
                result.push(list[i])
            }
        }
        return result
    }

    addChange(base, e) {
        // let json = {}
        // console.log(base.original)
        // for (let key in e){
        //     if(e[key]!=base.original[key]&&typeof e[key]!="object"){
        //         json[key] = e[key]
        //     }
        // }
        // alert("修改了信息："+JSON.stringify(json))
        // console.log("modify user" + e)
        user_service.modifyUser(e.id, e.roleId, e.username, e.name, e.mobile, e.customerId, e.customerProxyId).then(response => {
            if (response.errorCode == 0) {
                this.setState({
                    open: true,
                    message: '修改用户成功',
                    severity: 'success',
                })
                this.setState({
                    page: -1
                }, this.obtainNextUser());
                // this.obtainUser(this.props.totalCount)
                setTimeout(() => {
                    this.setState({
                        open: false
                    })
                }, 3000)
            } else {
                this.setState({
                    open: true,
                    message: '修改用户失败',
                    severity: 'error',
                })
                setTimeout(() => {
                    this.setState({
                        open: false
                    })
                }, 3000)
            }
        })

    }

    render() {
        let dataList = this.props.datas;
        if (dataList.length > 0) {
            for (let i = 0; i < dataList.length; i++) {
                dataList[i]['num'] = i + 1;
                dataList[i]['tableCheck'] = false;
            }
        }
        const columns = [{
            Header: <Checkbox color="primary" disabled />,
            width: 65,
            accessor: 'edit',
            Cell: <Checkbox color="primary" />,
            sortable: false
        }, {
            Header: '序号',
            width: 65,
            accessor: 'num'
        }, {
            Header: '客户姓名',
            accessor: 'name'
        }, {
            Header: '客户电话',
            accessor: 'mobile'
        }, {
            Header: '用户邮箱',
            accessor: 'email'
        }, {
            Header: '客户地址',
            accessor: 'address',
        }, {
            Header: '所属客户',
            accessor: 'customerName',
        }, {
            Header: '所属部门',
            accessor: 'customerProxyName',
        }, {
            Header: '用户权限',
            accessor: 'roleName',
        }, {
            Header: '创建时间',
            accessor: 'createTime',
            width: 180,
            Cell: row => (<div>
                {datetimeService.formatTimeStampToDateTime(row.original.createTime)}
            </div>)
        }, {
            Header: '操作',
            accessor: 'action',
            width: 200,
            Cell: row => {
                let base = JSON.parse(JSON.stringify(row))
                // console.log("row: " + JSON.stringify(row))
                return (<div>
                    <UserAdd className='cursor-style' data={row.original} onChangeData={(e) => {
                        this.addChange(base, e)
                        // this.props.obtainUsers({
                        //     datas: this.changeData(e.id,e,dataList)
                        // })   
                    }} />&nbsp;&nbsp;&nbsp;
                    <a className='cursor-style' style={{ color: 'orange' }} onClick={() => {
                        let id = row.original.id
                        user_service.changePassword(id).then(response => {
                            if (response.errorCode == 0) {
                                this.setState({
                                    open: true,
                                    message: '重置成功',
                                    severity: 'success',
                                })
                                setTimeout(() => {
                                    this.setState({
                                        open: false
                                    })
                                }, 3000)
                            } else {
                                this.setState({
                                    open: true,
                                    message: '重置失败',
                                    severity: 'error',
                                })
                                setTimeout(() => {
                                    this.setState({
                                        open: false
                                    })
                                }, 3000)
                            }
                        })
                        // alert(JSON.stringify(row.original.id))
                    }}>重置</a>&nbsp;&nbsp;&nbsp;
                    <a className='cursor-style' style={{ color: 'blue' }} onClick={() => {
                        // alert(row.original.locked)
                        let id = row.original.id
                        if (row.original.locked) {
                            user_service.unlock(id).then(response => {
                                if (response.errorCode == 0) {
                                    this.setState({
                                        open: true,
                                        message: '启用成功',
                                        severity: 'success',
                                    })
                                    // this.obtainUser(this.props.totalCount)
                                    // this.obtainUser(this.props.totalCount)
                                    this.setState({
                                        page: -1
                                    }, this.obtainNextUser());
                                    setTimeout(() => {
                                        this.setState({
                                            open: false
                                        })
                                    }, 3000)
                                } else {
                                    this.setState({
                                        open: true,
                                        message: '启用失败',
                                        severity: 'error',
                                    })
                                    setTimeout(() => {
                                        this.setState({
                                            open: false
                                        })
                                    }, 3000)
                                }
                            })
                        } else {
                            user_service.lock(id).then(response => {
                                if (response.errorCode == 0) {
                                    this.setState({
                                        open: true,
                                        message: '禁用成功',
                                        severity: 'success',
                                    })
                                    // this.obtainUser(this.props.totalCount)
                                    // this.obtainUser(this.props.totalCount)
                                    this.setState({
                                        page: -1
                                    }, this.obtainNextUser());
                                    setTimeout(() => {
                                        this.setState({
                                            open: false
                                        })
                                    }, 3000)
                                } else {
                                    this.setState({
                                        open: true,
                                        message: '禁用失败',
                                        severity: 'error',
                                    })
                                    setTimeout(() => {
                                        this.setState({
                                            open: false
                                        })
                                    }, 3000)
                                }
                            })
                        }

                    }}>{row.original.locked ? "启用" : "禁用"}</a>&nbsp;&nbsp;&nbsp;
                    <a className='cursor-style' style={{ color: 'red' }} onClick={() => {
                        this.setState({
                            confirm: true,
                            content: '确定删除用户名为' + row.original.name + "的用户嘛？",
                            data: row.original
                        })
                    }}>删除</a>
                </div>)
            }
        }];

        return (
            <div className="client-panel">
                <div className="client-top">
                    <div className="client-bread">
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/#/dashboard">首页</a></BreadcrumbItem>
                            <BreadcrumbItem active>用户管理</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="client-search">
                        <InputGroup className="client-search-input">
                            <Input placeholder="请输入搜索内容" onChange={(e) => {
                                this.setState({
                                    search: e.target.value
                                })
                            }} />
                            <InputGroupAddon addonType="append">
                                <Button color="secondary" onClick={() => {
                                    // this.obtainUser(this.props.totalCount)
                                    this.setState({
                                        page: -1
                                    }, () => {
                                        this.obtainNextUser();
                                    });
                                }}>搜索</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
                <div className="table-panel">
                    {/* <ReactTable
                        data={dataList}
                        columns={columns}
                        defaultPageSize={this.state.pageSize}
                        className="-highlight table-row"
                        pageSizeOptions={this.state.pageSizeList}
                        previousText={"上一页"}
                        nextText={"下一页"}
                        noDataText={"没有数据"}
                        pageText={"当前页"}
                        ofText={"总页数"}
                        rowsText={"条/页"}
                    /> */}
                    <ReactTable
                        data={dataList}
                        pages={this.props.totalPage}
                        defaultPageSize={this.state.pageSize}
                        columns={columns}
                        // defaultPageSize={this.state.pageSize}
                        className="-highlight table-row"
                        manual
                        // onFetchData={() => {
                        //     
                        // }}
                        onPageChange = {
                            (pindex) => {
                                this.setState({
                                    page: pindex - 1
                                }, () => {
                                    this.obtainNextUser()
                                })
                            }
                        }
                        previousText={"上一页"}
                        nextText={"下一页"}
                        noDataText={"没有数据"}
                        pageText={"当前页"}
                        ofText={"总页数"}
                        rowsText={"条/页"}
                    />
                </div>
                <MyMessage open={this.state.open} message={this.state.message} severity={this.state.severity} />
                <MyConfirm open={this.state.confirm} title={"删除用户"} content={this.state.content} data={this.state.data}
                    onCancel={(e) => {
                        this.setState({
                            confirm: false
                        })
                    }} onOk={(e) => {
                        this.setState({
                            confirm: false
                        })
                        user_service.remove(e.data.id).then(response => {
                            if (response.errorCode == 0) {
                                this.setState({
                                    open: true,
                                    message: '删除用户成功',
                                    severity: 'success',
                                })
                                // this.obtainUser(this.props.totalCount)
                                this.obtainUser(this.props.totalCount)
                                setTimeout(() => {
                                    this.setState({
                                        open: false
                                    })
                                }, 3000)
                            } else {
                                this.setState({
                                    open: true,
                                    message: '删除用户失败',
                                    severity: 'error',
                                })
                                setTimeout(() => {
                                    this.setState({
                                        open: false
                                    })
                                }, 3000)
                            }
                        })
                    }} />
            </div>
        );
    }

}

const User = connect(mapStateToProps, mapDispatchToProps)(UserComponent);

export default User;
