import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem, InputGroup, InputGroupAddon, Button, Input} from "reactstrap";
import {Link} from 'react-router-dom'
import '../../scss/client.scss';
import {render} from "react-dom";

import {connect} from 'react-redux';

// Import React Table
import ReactTable from "react-table";
import {machine_service} from "../../service/machine";

import {obtainDevice} from "../../action";

function mapStateToProps(state) {
    return {
        devices: state.machineReducer.devices,
        totalCount: state.machineReducer.totalCount
    }
}

const mapDispatchToProps = {
    obtainDevice
}

class DeviceComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: null,
            isProxy: null,
            pageSize: 10,
            pageSizeList: [10, 15, 20, 50]
        }
    }

    getDevice(pageSize) {
        machine_service.obtainDevice(this.state.search, 0, pageSize).then(response => {
            response = response.data;
            this.props.obtainDevice({
                size: response.size,
                page: response.page,
                upPage: response.upPage,
                nextPage: response.nextPage,
                totalCount: response.totalCount,
                totalPage: response.totalPage,
                firstResult: response.firstResult,
                lastResult: response.lastResult,
                devices: response.datas
            })
        })
    }

    componentDidMount() {
        machine_service.obtainDevice(this.state.search, 0, this.state.pageSize).then(response => {
            response = response.data;
            this.props.obtainDevice({
                size: response.size,
                page: response.page,
                upPage: response.upPage,
                nextPage: response.nextPage,
                totalCount: response.totalCount,
                totalPage: response.totalPage,
                firstResult: response.firstResult,
                lastResult: response.lastResult,
                devices: response.datas
            })
            this.getDevice(response.totalCount)
        })
    }

    refresh_device = () => {

    }


    render() {
        let columns = [{
            Header: '设备MAC',
            accessor: 'mac'
        }, {
            Header: '设备名称',
            accessor: 'name'
        }, {
            Header: '在线状态',
            Cell: row => (<div>{row.original.isOnline === true ? "在线" : "离线"}</div>)
        }, {
            Header: '所属客户',
            accessor: 'customerName',
        }, {
            Header: '所属科室',
            accessor: 'customerProxyName'
        }, {
            Header: '产品型号',
            accessor: 'productTypeCode'
        }, {
            Header: '操作',
            accessor: 'action',
            Cell: row => (<div>
                <Link style={{color: 'blue', cursor: 'pointer'}} to={"/device/detail/" + row.original.id}>查看</Link>
            </div>)
        }]

        return (
            <div className="client-panel">
                <div className="client-top">
                    <div className="client-bread">
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/#/dashboard">首页</a></BreadcrumbItem>
                            <BreadcrumbItem active>设备管理</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="client-search">
                        <InputGroup className="client-search-input">
                            <Input placeholder="请输入搜索内容" onChange={(e) => {
                                this.setState({
                                    search: e.target.value
                                })
                            }}/>
                            <InputGroupAddon addonType="append">
                                <Button color="secondary" onClick={() => {
                                    this.getDevice(this.props.totalCount)
                                }}>搜索</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
                <div className="table-panel">
                    <ReactTable
                        data={this.props.devices}
                        columns={columns}
                        defaultPageSize={this.state.pageSize}
                        className="-highlight table-row"
                        pageSizeOptions={this.state.pageSizeList}
                        previousText={"上一页"}
                        nextText={"下一页"}
                        noDataText={"没有数据"}
                        pageText={"当前页"}
                        ofText={"总页数"}
                        rowsText={"条/页"}
                    />
                </div>
            </div>
        );
    }

}

const Device = connect(mapStateToProps, mapDispatchToProps)(DeviceComponent);

export default Device;

