import React,{Component} from 'react'
import Alert from '@material-ui/lab/Alert'
import {Snackbar} from "@material-ui/core";

class MyMessage extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        // console.log(this.props)
        return (
            <Snackbar open={this.props.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity={this.props.severity}>
                    {this.props.message}
                </Alert>
            </Snackbar>
        );
    }

}

export default MyMessage
