import React from 'react'
import {Card} from 'react-bootstrap'
import {connect} from 'react-redux'

import {
    Switch, Slider, Select, MenuItem
} from '@material-ui/core';

import {obtainRunningStatus} from '../../action'
import {machine_service} from "../../service/machine";

function mapStateToProps(state) {
    return {
        power_status: state.statusReducer.power_status,
        run_status: state.statusReducer.run_status,
        mode: state.statusReducer.mode,
        level: state.statusReducer.level,
        voice: state.statusReducer.voice,
        swing: state.statusReducer.swing,
        timestamp: state.statusReducer.run_status_timestamp,
        o3: state.statusReducer.o3
    }
}

const mapDispatchToProps = {
    obtainRunningStatus: obtainRunningStatus
}

class RunningStatusComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [1, 2, 3]
        }
        this.power.bind(this);
        this.run.bind(this);
        this.voice.bind(this);
        this.level.bind(this);
    }

    componentDidMount() {
        if (this.props.mac !== undefined && this.props.mac !== "") {
            this.obtain_running_status(this.props.mac);
        }
    }

    obtain_running_status(mac) {
        machine_service.obtainRunningStatus(mac).then(response => {
            response = response.data;
            if (response === null) return;
            this.props.obtainRunningStatus({
                power_status: response.power,
                run_status: response.run,
                mode: response.mode,
                level: response.level,
                voice: response.voice,
                run_status_timestamp: response.updateTime
            })
        })
    }

    power() {
        let mac = this.props.mac;
        let power_status = this.props.power_status;
        let target_power = power_status == 1 ? 0 : 1;
        this.props.send_message("/client/XD/" + mac + "/cmd", JSON.stringify({"power": target_power}));
        this.props.obtainRunningStatus({
            power_status: target_power,
            power_controlled: true
        })
        setTimeout(() => {
            this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "status"}))
        }, 1000)
    }

    run() {
        let mac = this.props.mac;
        let run_status = this.props.run_status;
        let target_run = run_status == 1 ? 0 : 1;
        this.props.send_message("/client/XD/" + mac + "/cmd", JSON.stringify({"run": target_run}));
        this.props.obtainRunningStatus({
            run_status: target_run,
            run_controlled: true
        })
        setTimeout(() => {
            this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "status"}))
        }, 1000)
    }

    voice() {
        let mac = this.props.mac;
        let voice = this.props.voice;
        let target_voice = voice == 1 ? 0 : 1;
        this.props.send_message("/client/XD/" + mac + "/cmd", JSON.stringify({"voice": target_voice}));
        this.props.obtainRunningStatus({
            voice: target_voice,
            voice_controlled: true
        })
        setTimeout(() => {
            this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "status"}))
        }, 1000)
    }

    //todo Level总共有1-20个档位，相应的前端需要更新一下，目前Sidebar无法满足要求
    level(level) {
        let mac = this.props.mac;
        this.props.send_message("/client/XD/" + mac + "/cmd", JSON.stringify({"level": level}));
        this.props.obtainRunningStatus({
            level: level,
            level_controlled: true
        })
        setTimeout(() => {
            this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "status"}))
        }, 1000)
    }

    wind() {
        let mac = this.props.mac;
        let swing = this.props.swing;
        let target_swing = swing == 1 ? 0 : 1;
        this.props.send_message("/client/XD/" + mac + "/cmd", JSON.stringify({"swing": target_swing}));
        this.props.obtainRunningStatus({
            swing: swing,
            swing_controlled: true
        })
        setTimeout(() => {
            this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "status"}))
        }, 1000)
    }

    O3() {
        let mac = this.props.mac;
        let o3 = this.props.o3;
        let target_o3 = o3 == 1 ? 0 : 1;
        this.props.send_message("/client/XD/" + mac + "/cmd", JSON.stringify({"o3": target_o3}));
        this.props.obtainRunningStatus({
            o3: target_o3,
            o3_controlled: true
        })
        setTimeout(() => {
            this.props.send_message("/client/XD/" + this.props.mac + "/report", JSON.stringify({"item": "status"}))
        }, 1000)
    }

    render() {
        const marks = [
            {
                value: 0,
                label: '0',
            },
            {
                value: 1,
                label: '1',
            },
            {
                value: 2,
                label: '2',
            },
            {
                value: 3,
                label: '3',
            },
        ];
        const mode_list = ["程控模式", "手动模式", "定时模式"]
        if (this.props.isOnline === true) {
            return (
                <Card className="detail-card">
                    <Card.Header>控制单元</Card.Header>
                    <Card.Body>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    开关
                                </div>
                                <div className="circle-bottom">
                                    <Switch
                                        checked={this.props.power_status === 1}
                                        onChange={() => {
                                            this.power();
                                        }}
                                        color="primary"/>
                                </div>
                            </div>
                        </div>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    运行
                                </div>
                                <div className="circle-bottom">
                                    <Switch
                                        checked={this.props.run_status === 1 && this.props.power_status === 1}
                                        onChange={() => {
                                            this.run();
                                        }}
                                        color="primary"/>
                                </div>
                            </div>
                        </div>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    模式
                                </div>
                                <div className="circle-bottom control-bottom">
                                    {mode_list[this.props.mode]}
                                </div>
                            </div>
                        </div>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    风速
                                </div>
                                <div className="circle-bottom select">
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.props.level}
                                        onChange={(e) => {
                                            this.level(e.target.value)
                                        }}
                                    >
                                        {this.state.list.map((item, index) => {
                                            return (<MenuItem value={item}>{item}</MenuItem>)
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    语音
                                </div>
                                <div className="circle-bottom">
                                    <Switch
                                        checked={this.props.voice === 1}
                                        onChange={() => {
                                            this.voice();
                                        }}
                                        color="primary"/>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.wind_direction &&
                            <div className="detail-circle-panel">
                                <div className="detail-circle">
                                    <div className="circle-top">
                                        扫风
                                    </div>
                                    <div className="circle-bottom">
                                        <Switch
                                            checked={this.props.swing === 1 && this.props.power_status === 1 && this.props.run_status === 1}
                                            onChange={() => {
                                                this.wind();
                                            }}
                                            color="primary"/>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.props.o3_enabled &&
                            <div className="detail-circle-panel">
                                <div className="detail-circle">
                                    <div className="circle-top">
                                        臭氧
                                    </div>
                                    <div className="circle-bottom">
                                        <Switch
                                            checked={this.props.o3 === 1 && this.props.power_status === 1 && this.props.run_status === 1}
                                            onChange={() => {
                                                this.O3();
                                            }}
                                            color="primary"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </Card.Body>
                </Card>
            )
        } else {
            return (
                <Card className="detail-card">
                    <Card.Header>控制单元</Card.Header>
                    <Card.Body>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    开关
                                </div>
                                <div className="circle-bottom">
                                    <Switch
                                        checked={this.props.power_status === 1}
                                        onChange={() => {
                                            this.power();
                                        }}
                                        color="primary" disabled/>
                                </div>
                            </div>
                        </div>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    运行
                                </div>
                                <div className="circle-bottom">
                                    <Switch
                                        checked={this.props.run_status === 1}
                                        onChange={() => {
                                            this.run();
                                        }}
                                        color="primary" disabled/>
                                </div>
                            </div>
                        </div>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    模式
                                </div>
                                <div className="circle-bottom control-bottom">
                                    {mode_list[this.props.mode]}
                                </div>
                            </div>
                        </div>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    风速
                                </div>
                                <div className="circle-bottom select">
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.props.level}
                                        onChange={(e) => {
                                            this.level(e.target.value)
                                        }} disabled
                                    >
                                        {this.state.list.map((item, index) => {
                                            return (<MenuItem value={item}>{item}</MenuItem>)
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="detail-circle-panel">
                            <div className="detail-circle">
                                <div className="circle-top">
                                    语音
                                </div>
                                <div className="circle-bottom">
                                    <Switch
                                        checked={this.props.voice === 1}
                                        onChange={() => {
                                            this.voice();
                                        }}
                                        color="primary" disabled/>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.wind_direction &&
                            <div className="detail-circle-panel">
                                <div className="detail-circle">
                                    <div className="circle-top">
                                        扫风
                                    </div>
                                    <div className="circle-bottom">
                                        <Switch
                                            checked={this.props.swing === 1}
                                            onChange={() => {
                                                this.wind();
                                            }}
                                            color="primary" disabled/>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.props.o3_enabled &&
                            <div className="detail-circle-panel">
                                <div className="detail-circle">
                                    <div className="circle-top">
                                        臭氧
                                    </div>
                                    <div className="circle-bottom">
                                        <Switch
                                            checked={this.props.o3 === 1}
                                            onChange={() => {
                                                this.O3();
                                            }}
                                            color="primary" disabled/>
                                    </div>
                                </div>
                            </div>
                        }
                    </Card.Body>
                </Card>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RunningStatusComponent)
