import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap'
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import 'moment/locale/th';
import {machine_service} from "../../service/machine";

import {connect} from 'react-redux'

import BasicInfo from './BasicInfo'
import SurplusInfo from './SurplusInfo'
import SensorStatus from './SensorStatus'
import Bactericidal from './BactericidalInfo'
import RunningStatus from './RunningStatus'
import TimingMode from './TimingMode'
import ControlMode from './ControlMode'
import DeviceUser from './DeviceUser'
import SystemLog from './SystemLog'
import Alarm from './Alarm'

import {
    obtainRunningStatus,
    obtainControlConfig,
    obtainSurplus,
    obtainBactericidal,
    refreshSensorStatus,
    obtainTimingConfig
} from "../../action";
import {util_service} from "../../service/util";

function mapStateToProps(state) {
    return {
        power_status: state.statusReducer.power_status,
        mode: state.statusReducer.mode,
        level: state.statusReducer.level,
        control_1_enabled: state.timingReducer.control_1_enabled,
        control_2_enabled: state.timingReducer.control_2_enabled,
        control_3_enabled: state.timingReducer.control_3_enabled,
        control_4_enabled: state.timingReducer.control_4_enabled,
        control_5_enabled: state.timingReducer.control_5_enabled,
        control_6_enabled: state.timingReducer.control_6_enabled,
    }
}

const mapDispatchToProps = {
    obtainRunningStatus, obtainControlConfig, obtainSurplus, obtainBactericidal, refreshSensorStatus, obtainTimingConfig
}

class DeviceDetailComponent extends Component {
    constructor() {
        super();
        this.state = {
            mqtt: '',
            deviceName: '',
            mac: '',
            company: '',
            deviceClass: '',
            deviceCode: '',
            isOnline: false,
            location: '',
            softVersion: '',
            firmVersion: '',
            latestVersion: '',
            deviceImage: '',
            deviceQrcode: '',
            factorNum: '',
            meshLife: 0,
            factorLife: 0,
            log_list: [1, 2, 3, 4],
            pm2_5_enabled: false,
            temp_enabled: false,
            temp_out_enabled: false,
            humid_enabled: false,
            hcho_enabled: false,
            tvoc_enabled: false,
            uv_enabled: false,
            o3_enabled: false
        }
        this.send_message = this.send_message.bind(this);
    }

    componentDidMount() {
        const mqtt = require('mqtt');
        let client_id = 'jg-hospital' + Math.floor(Math.random() * 100);
        console.log("client_id: " + client_id)
        const options = {
            protocol: 'ws',
            clientId: client_id
        };
        let id = this.props.match.params.deviceId;
        machine_service.profile(id).then(response => {
            response = response.data;
            let deviceName = response.name;
            let mac = response.mac;
            let company = response.customerName;
            let department = response.customerProxyName;
            let deviceClass = response.deviceTypeBean.name;
            let deviceCode = response.deviceTypeBean.productTypeCode;
            let location = response.provinceName + response.cityName;
            let softVersion = response.swVersion;
            let firmVersion = response.hwVersion;
            let latestVersion = response.existNewVersion;
            let deviceImage = response.deviceTypeBean.images;
            let deviceQrcode = response.deviceTypeBean.qrticket;
            let factorNum = response.deviceTypeBean.factorNum;
            let meshLife = response.deviceTypeBean.meshLife;
            let factorLife = response.deviceTypeBean.factorLife;
            let winddirection = response.deviceTypeBean.winddirection;
            this.setState({
                deviceName: deviceName,
                mac: mac,
                company: company,
                department: department,
                deviceClass: deviceClass,
                deviceCode: deviceCode,
                location: location,
                softVersion: softVersion,
                firmVersion: firmVersion,
                latestVersion: latestVersion,
                deviceImage: deviceImage,
                deviceQrcode: deviceQrcode,
                factorNum: factorNum,
                meshLife: meshLife,
                factorLife: factorLife,
                windirection: winddirection,

            })


            //"bodyinduction":true,"hcho":true,"tvoc":true,"sparelamp":true,"level":3,"voice":true,"uvStrength":true,"o3":true,"co2":false,"
            this.setState({
                pm2_5_enabled: response.deviceTypeBean.pm2_5,
                temp_enabled: response.deviceTypeBean.temp,
                temp_out_enabled: response.deviceTypeBean.temp_out,
                humid_enabled: response.deviceTypeBean.humidity,
                hcho_enabled: response.deviceTypeBean.hcho,
                tvoc_enabled: response.deviceTypeBean.tvoc,
                uv_enabled: response.deviceTypeBean.uvStrength,
                o3_enabled: response.deviceTypeBean.o3
            })

            let client = mqtt.connect('ws://finley:finley@47.103.193.179:8083/mqtt', options);


            client.on('connect', () => {
                let topic = "/XD/+/" + mac + "/+/+/+"
                client.subscribe(topic);
                client.publish("/client/XD/" + mac + "/report", JSON.stringify({"item": "status"}));
                client.publish("/client/XD/" + mac + "/report", JSON.stringify({"item": "surplus"}));
                client.publish("/client/XD/" + mac + "/report", JSON.stringify({"item": "sensor"}));
                client.publish("/client/XD/" + mac + "/report", JSON.stringify({"item": "timeseg"}));
                client.publish("/client/XD/" + mac + "/report", JSON.stringify({"item": "uvtime"}));
                client.publish("/client/XD/" + mac + "/report", JSON.stringify({"item": "uvstatus"}));
            })

            let that = this;

            client.on('message', function (topic, message) {
                that.setState({isOnline: true});
                message = JSON.parse(message.toString())
                if (topic.indexOf("sys_status") >= 0) {
                    let power = message.power;
                    let level = message.level;
                    let swing = message.swing;
                    let voice = message.voice;
                    let run = message.run;
                    let mode = message.mode;
                    that.props.obtainRunningStatus({
                        power_status: power,
                        run_status: run,
                        mode: mode,
                        level: level,
                        voice: voice,
                        swing: swing,

                    })
                    if (message.hasOwnProperty("o3")) {
                        let o3 = message.o3;
                        that.props.obtainRunningStatus({o3: o3})
                    }

                    let timeseg1 = message.timeseg1;
                    let timeseg2 = message.timeseg2;
                    let timeseg3 = message.timeseg3;
                    let timeseg4 = message.timeseg4;
                    let timeseg5 = message.timeseg5;
                    let timeseg6 = message.timeseg6;
                    console.log("timeseg1: " + timeseg1)

                    that.props.obtainControlConfig({
                        control_1_enabled: timeseg1,
                        control_2_enabled: timeseg2,
                        control_3_enabled: timeseg3,
                        control_4_enabled: timeseg4,
                        control_5_enabled: timeseg5,
                        control_6_enabled: timeseg6,
                    })


                } else if (topic.indexOf("timer") > 0) {
                    let h_time = message.h;
                    let m_time = message.m;
                    that.props.obtainTimingConfig({
                        h_time: h_time,
                        m_time: m_time
                    })
                } else if (topic.indexOf("sys_surplus") > 0) {
                    let surplus_remains = [message["1"], message["2"], message["3"]];
                    that.props.obtainSurplus({
                        surplus_remains: surplus_remains
                    })
                } else if (topic.indexOf("uvtime") > 0) {
                    let uvtime = [];
                    for (let i = 0; i < factorNum; i++) {
                        uvtime.push(message[i + 1])
                    }
                    that.props.obtainBactericidal({
                        uv_remains: uvtime
                    })
                } else if (topic.indexOf("uv_status") > 0) {
                    let uv_h = message.uv_h;
                    let uv_l = message.uv_l;
                    let uv_status = [];
                    for (let i = 0; i < factorNum; i++) {
                        uv_status.push(0)
                    }
                    if (factorNum <= 8) {
                        let l_status = util_service.to_binary(uv_l);
                        for (let i = 0; i < l_status.length; i++) {
                            if (l_status.charAt(i) === '1') {
                                uv_status[i] = 1;
                            } else {
                                uv_status[i] = 0;
                            }
                        }
                        console.log("uv_status: " + JSON.stringify(uv_status))
                        // 0000 1111对应的是第1根灯管-第8个灯管
                        let uv_health = [];
                        for (let i = 0; i < factorNum; i++) {
                            uv_health.push(uv_status[i]);
                        }
                        that.props.obtainBactericidal({
                            uv_health: uv_health
                        })
                        console.log("uv_health: " + JSON.stringify(uv_health))
                    } else {
                        let l_status = util_service.to_binary(uv_l);
                        let intermediate = l_status.length <= 8 ? l_status : 8;
                        console.log("intermediate: " + intermediate)
                        for (let i = 0; i < intermediate; i++) {
                            if (l_status.charAt(intermediate - i - 1) === '1') {
                                uv_status[i] = 1;
                            } else {
                                uv_status[i] = 0;
                            }
                        }
                        //h status 0000 1111
                        let h_status = util_service.to_binary(uv_h);
                        for (let i = 0; i < factorNum - 8; i++) {
                            if (h_status[h_status.length - i - 1] === '1') {
                                uv_status[8 + i] = 1;
                            } else {
                                uv_status[8 + i] = 0;
                            }
                        }
                        that.props.obtainBactericidal({
                            uv_health: uv_status
                        })
                    }
                } else if (topic.indexOf("sensor") > 0) {
                    let pm2_5 = message["pm2.5"];
                    let co2 = message["co2"];
                    let temp = message["temp"];
                    let temp_out = message["temp_out"];
                    let humidity = message["humidity"];
                    let strength = 0;
                    if (message["uv_strength"] != undefined && message["uv_strength"] != "")
                        strength = message["uv_strength"];
                    that.props.refreshSensorStatus({
                        sensor_pm2_5: pm2_5,
                        sensor_co2: co2,
                        sensor_temp: temp,
                        sensor_temp_out: temp_out,
                        sensor_humidity: humidity,
                        sensor_uv_strength: strength
                    })

                } else if (topic.indexOf("timeseg123") > 0) {
                    let h_control_1s = message["1b_h"];
                    let m_control_1s = message["1b_m"];
                    let h_control_1e = message["1e_h"];
                    let m_control_1e = message["1e_m"];
                    let h_control_2s = message["2b_h"];
                    let m_control_2s = message["2b_m"];
                    let h_control_2e = message["2e_h"];
                    let m_control_2e = message["2e_m"];
                    let h_control_3s = message["3b_h"];
                    let m_control_3s = message["3b_m"];
                    let h_control_3e = message["3e_h"];
                    let m_control_3e = message["3e_m"];
                    that.props.obtainControlConfig({
                        h_control_1s: h_control_1s,
                        m_control_1s: m_control_1s,
                        h_control_1e: h_control_1e,
                        m_control_1e: m_control_1e,
                        h_control_2s: h_control_2s,
                        m_control_2s: m_control_2s,
                        h_control_2e: h_control_2e,
                        m_control_2e: m_control_2e,
                        h_control_3s: h_control_3s,
                        m_control_3s: m_control_3s,
                        h_control_3e: h_control_3e,
                        m_control_3e: m_control_3e,
                    })
                } else if (topic.indexOf("timeseg456") > 0) {
                    let h_control_4s = message["4b_h"];
                    let m_control_4s = message["4b_m"];
                    let h_control_4e = message["4e_h"];
                    let m_control_4e = message["4e_m"];
                    let h_control_5s = message["5b_h"];
                    let m_control_5s = message["5b_m"];
                    let h_control_5e = message["5e_h"];
                    let m_control_5e = message["5e_m"];
                    let h_control_6s = message["6b_h"];
                    let m_control_6s = message["6b_m"];
                    let h_control_6e = message["6e_h"];
                    let m_control_6e = message["6e_m"];
                    that.props.obtainControlConfig({
                        h_control_4s: h_control_4s,
                        m_control_4s: m_control_4s,
                        h_control_4e: h_control_4e,
                        m_control_4e: m_control_4e,
                        h_control_5s: h_control_5s,
                        m_control_5s: m_control_5s,
                        h_control_5e: h_control_5e,
                        m_control_5e: m_control_5e,
                        h_control_6s: h_control_6s,
                        m_control_6s: m_control_6s,
                        h_control_6e: h_control_6e,
                        m_control_6e: m_control_6e,
                    })
                }
            })
            this.setState({mqtt: client})
        })
    }

    send_message(topic, message) {
        this.state.mqtt.publish(topic, message)
    }


    render() {
        let mac = this.state.mac;
        return (
            <div className="client-panel">
                <div className="client-top">
                    <Breadcrumb>
                        <BreadcrumbItem><a href="/#/dashboard">首页</a></BreadcrumbItem>
                        <BreadcrumbItem><a href="/#/device">设备管理</a></BreadcrumbItem>
                        <BreadcrumbItem active>设备详情</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="detail-panel">
                    <Row>
                        <Col xl='6'>
                            <div className='detail-item'>
                                <BasicInfo deviceImage={this.state.deviceImage} isOnline={this.state.isOnline}
                                           deviceClass={this.state.deviceClass} mac={mac}
                                           department={this.state.department} location={this.state.location}
                                           deviceQrcode={this.state.deviceQrcode} deviceName={this.state.deviceName}
                                           deviceCode={this.state.deviceCode} company={this.state.company}
                                           softVersion={this.state.softVersion}
                                           latestVersion={this.state.latestVersion}
                                           firmVersion={this.state.firmVersion}/>
                            </div>
                        </Col>
                        <Col xl='6'>
                            <div className='detail-item'>
                                <Card className="detail-card">
                                    <Card.Header>部件状态</Card.Header>
                                    <Card.Body className="lv-panel">
                                        <Row>
                                            <div className='container'>
                                                <SurplusInfo mac={mac} meshLife={this.state.meshLife}
                                                             send_message={this.send_message}/>
                                            </div>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <div className='container'>
                                                <Bactericidal mac={mac} factorNum={this.state.factorNum}
                                                              factorLife={this.state.factorLife}
                                                              send_message={this.send_message}/>
                                            </div>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl='6'>
                            <div className='detail-item'>
                                <SensorStatus mac={mac} pm2_5_enabled={this.state.pm2_5_enabled}
                                              temp_enabled={this.state.temp_enabled}
                                              temp_out_enabled={this.state.temp_out_enabled}
                                              humid_enabled={this.state.humid_enabled}
                                              hcho_enabled={this.state.hcho_enabled}
                                              tvoc_enabled={this.state.tvoc_enabled}
                                              uv_enabled={this.state.uv_enabled}/>
                            </div>
                        </Col>
                        <Col xl='6'>
                            <div className='detail-item'>
                                <RunningStatus isOnline={this.state.isOnline} mac={mac} send_message={this.send_message}
                                               mode={this.props.mode}
                                               wind_direction={this.state.windirection}
                                               o3_enabled={this.state.o3_enabled}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl='6'>
                            <div className='detail-item'>

                                <Card className="detail-card over-card">
                                    <Card.Header>定时设定</Card.Header>
                                    <Card.Body>
                                        <TimingMode mac={mac} send_message={this.send_message}
                                                    isOnline={this.state.isOnline}/>
                                        <ControlMode mac={mac} send_message={this.send_message}
                                                     isOnline={this.state.isOnline}/>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col xl='6'>
                            <div className='detail-item'>
                                <DeviceUser mac={this.props.match.params.deviceId}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl='6'>
                            <div className='log-item '>
                                <SystemLog mac={mac}/>
                            </div>
                        </Col>
                        <Col xl='6'>
                            <div className='log-item '>
                                <Alarm mac={mac} id={this.props.match.params.deviceId}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}

const DeviceDetail = connect(mapStateToProps, mapDispatchToProps)(DeviceDetailComponent)
export default DeviceDetail
