import {OBTAIN_HOSPITAL_NAME} from '../constant/action-type'

const initialState = {
    hospitalName: ""
};

function profileReducer(state = initialState, action) {
    if (action.type === OBTAIN_HOSPITAL_NAME) {
        return Object.assign({}, state, {
            hospitalName: "某省省人民医院空气消毒器智能监控系统"
        })
    }
    return state;
};

export default profileReducer;