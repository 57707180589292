import React from 'react'
import {Button, Card, FormControl, InputGroup, Modal} from 'react-bootstrap'
import {connect} from 'react-redux'
import {machine_service} from "../../service/machine";
import {datetimeService} from "../../service/datetime";


import {obtainBactericidal} from '../../action'
import {util_service} from "../../service/util";

function mapStateToProps(state) {
    return {
        power_status: state.statusReducer.power_status,
        run_status: state.statusReducer.run_status,
        uv_count: state.statusReducer.uv_count,
        uv_remains: state.statusReducer.uv_remains,
        uv_health: state.statusReducer.uv_health,
        uv_refresh_time: state.statusReducer.uv_refresh_time
    }
}

const mapDispatchToProps = {
    obtainBactericidal
}

class BactericidalInfoComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uv_edit_index: -1,
            modal_show: false,
            value: 0
        }
    }

    componentDidMount() {
        if (this.props.mac !== undefined && this.props.mac !== "") {
            this.obtain_bactericidal_status(this.props.mac);
        }
    }

    componentWillReceiveProps(next) {
        if (next.mac !== undefined && next.mac !== "") {
            this.obtain_bactericidal_status(next.mac);
        }
    }

    obtain_bactericidal_status(mac) {
        machine_service.obtainUVStatusAndTime(mac).then(response => {
            response = response.data;
            if (response === null) return;
            let bactericidal = util_service.interpret_bactericidal(response);
            let data = bactericidal.detail;
            this.props.obtainBactericidal({
                uv_count: this.props.factorNum,
                uv_remains: data,
                uv_refresh_time: response.updateTime
            })
        })
    }


    reset_uv = (e) => {
        let element = e.currentTarget
        let index = element.getAttribute("target-index")
        this.setState({uv_edit_index: index - 1})
        this.show();
    }

    confirm_reset = () => {
        let mac = this.props.mac;
        if (this.state.value >= 0 && this.state.value <= this.props.factorLife) {
            let message = "{\"" + (this.state.uv_edit_index + 1) + "\":" + this.state.value + "}";
            this.props.send_message("/client/XD/" + mac + "/set_uvtime", message);
            this.hide();
            setTimeout(() => {
                this.props.send_message("/client/XD/" + mac + "/report", JSON.stringify({"item": "uvstatus"}))
            }, 1000)
        }
    }

    show = () => {
        this.setState({
            modal_show: true
        })
    }

    hide = () => {
        this.setState({
            modal_show: false
        })
    }

    read_value = (e) => {
        let value = e.target.value;
        if (value == "" || (value <= this.props.factorLife && value >= 0)) {
            this.setState({value: value});
        } else {
            return;
        }
    }

    render() {

        // let surplus_count = this.props.surplus_count;
        let uv_remains = this.props.uv_remains;
        let uv_refresh_time = this.props.uv_refresh_time

        let time = datetimeService.formatTimeStampToDateTime(uv_refresh_time);

        // const lv_list = [{name: '杀菌因子1', value: '0小时'}, {name: '杀菌因子2', value: '0小时'}, {
        //     name: '杀菌因子3',
        //     value: '0小时'
        // }, {name: '杀菌因子4', value: '0小时'}];


        let uv_edit_index = this.state.uv_edit_index;

        return (
            <div>
                {uv_remains.map((item, index) => {
                    if (index + 1 <= this.props.factorNum) {
                        return (
                            <div className="lv-item" key={"bacter" + index}>
                                <div className="lv-item-left">
                                    杀菌{index + 1}累计：
                                </div>
                                <div className={uv_remains[index] >= this.props.factorLife ? "lv-item-middle warning": "lv-item-middle"}>
                                    {uv_remains[index]}小时
                                </div>
                                {this.props.power_status === 1 && this.props.run_status === 1 &&
                                <div
                                    className={this.props.uv_health[index] == 1 ? "lv-item-right active" : "lv-item-right inactive"}>
                                    <i className="cil-lightbulb"></i>&nbsp;
                                    <span className='action' onClick={this.reset_uv} target-index={index + 1}>重置</span>
                                </div>
                                }
                                {(this.props.power_status !== 1 || this.props.run_status !== 1) &&
                                <div className="lv-item-right inactive">
                                    <i className="cil-lightbulb"></i>&nbsp;
                                    <span>重置</span>
                                </div>
                                }
                            </div>
                        )
                    } else {
                        return (<div key={"bacter" + index}></div>)
                    }
                })}
                {/*<div className="lv-main">*/}
                {/*{lv_list.map((item, index) => {*/}
                {/*return (<div className="lv-item">*/}
                {/*<div className="lv-item-left">*/}
                {/*{item.name}：*/}
                {/*</div>*/}
                {/*<div className="lv-item-middle">*/}
                {/*{item.value}*/}
                {/*</div>*/}
                {/*<div className="lv-item-right">*/}
                {/*<i className="cil-lightbulb"></i>&nbsp;重置*/}
                {/*</div>*/}
                {/*</div>)*/}
                {/*})}*/}
                {/*</div>*/}
                <Modal
                    show={this.state.modal_show}
                    onHide={this.hide}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>重置时间（最大为{this.props.factorLife}小时）</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder={"请输入需要设定的时间，不得超过" + this.props.factorLife} onChange={this.read_value}
                                value={this.state.value}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>小时</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hide}>
                            取消
                        </Button>
                        <Button variant="primary" onClick={this.confirm_reset}>确认</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BactericidalInfoComponent)
