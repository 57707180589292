import axios from './request'

function obtain_captcha() {
    let url = "http://47.103.193.179:9090/Juguang/captcha";
    return axios.get(url, {withCredentials: true}).then(response => {
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(response)
            util_service.clearCredential();
            window.location.href = "/";
        }
    })
}

function login(username, password, captcha) {
    let url = "/login";
    let form = new URLSearchParams();
    form.append('username', username);
    form.append('password', password);
    form.append('captcha', captcha);
    form.append('rememberMe', "false");
    return axios.post(url, form, {withCredentials: true}).then(response => {
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(response)
            util_service.clearCredential();
            window.location.href = "/";
        }
    })
}

function overview() {
    let url = "/staticsRecord/count";
    return axios.get(url).then(response => {
        if (response.status === 200) {
            return response.data
        } else {
            console.error(response)
            util_service.clearCredential();
            window.location.href = "/";
        }
    })
}

function save2local(response) {
    localStorage.setItem('authenticated', true);
    localStorage.setItem('address', response.address);
    localStorage.setItem('admin', response.admin);
    localStorage.setItem('appId', response.appId);
    localStorage.setItem('cityId', response.cityId);
    localStorage.setItem('cityName', response.cityName);
    localStorage.setItem('creatAt', response.createTime);
    localStorage.setItem('customerId', response.customerId);
    localStorage.setItem('customerName', response.customerName);
    localStorage.setItem('customerProxyId', response.customerProxyId);
    localStorage.setItem('customerProxyName', response.customerProxyName);
    localStorage.setItem('deviceAlertCount', response.deviceAlertCount);
    localStorage.setItem('deviceCount', response.deviceCount);
    localStorage.setItem('districtId', response.districtId);
    localStorage.setItem('districtName', response.districtName);
    localStorage.setItem('email', response.email);
    localStorage.setItem('houseArea', response.houseArea);
    localStorage.setItem('id', response.id);
    localStorage.setItem('image', response.image);
    localStorage.setItem('isAdmin', response.isAdmin);
    localStorage.setItem('isCustomer', response.isCustomer);
    localStorage.setItem('isUser', response.isUser);
    localStorage.setItem('locked', response.locked);
    localStorage.setItem('loginDate', response.loginDate);
    localStorage.setItem('loginIp', response.loginIp);
    localStorage.setItem('mac', response.mac);
    localStorage.setItem('mobile', response.mobile);
    localStorage.setItem('name', response.name);
    localStorage.setItem('openid', response.openid);
    localStorage.setItem('permissions', response.permissions);
    localStorage.setItem('provinceId', response.provinceId);
    localStorage.setItem('provinceName', response.provinceName);
    localStorage.setItem('publicNumberId', response.publicNumberId);
    localStorage.setItem('remark', response.remark);
    localStorage.setItem('role', response.role);
    localStorage.setItem('roleId', response.roleId);
    localStorage.setItem('roleName', response.roleName);
    localStorage.setItem('source', response.source);
    localStorage.setItem('username', response.username);
}

function loadLocal(name) {
    return localStorage.getItem(name);
}

function loadCredential() {
    return {
        address: loadLocal('address'),
        admin: loadLocal('admin'),
        appId: loadLocal('appId'),
        cityId: loadLocal('cityId'),
        cityName: loadLocal('cityName'),
        createAt: loadLocal('createTime'),
        customerId: loadLocal('customerId'),
        customerName: loadLocal('customerName'),
        customerProxyId: loadLocal('customerProxyId'),
        customerProxyName: loadLocal('customerProxyName'),
        deviceAlertCount: loadLocal('deviceAlertCount'),
        deviceCount: loadLocal('deviceCount'),
        districtId: loadLocal('districtId'),
        districtName: loadLocal('districtName'),
        email: loadLocal('email'),
        houseArea: loadLocal('houseArea'),
        id: loadLocal('id'),
        image: loadLocal('image'),
        isAdmin: loadLocal('isAdmin'),
        isCustomer: loadLocal('isCustomer'),
        isUser: loadLocal('isUser'),
        locked: loadLocal('locked'),
        loginDate: loadLocal('loginDate'),
        loginIp: loadLocal('loginIp'),
        mac: loadLocal('mac'),
        mobile: loadLocal('mobile'),
        name: loadLocal('name'),
        openid: loadLocal('openid'),
        permissions: loadLocal('permissions'),
        provinceId: loadLocal('provinceId'),
        provinceName: loadLocal('provinceName'),
        publicNumberId: loadLocal('publicNumberId'),
        remark: loadLocal('remark'),
        role: loadLocal('role'),
        roleId: loadLocal('roleId'),
        roleName: loadLocal('roleName'),
        source: loadLocal('source'),
        username: loadLocal('username')
    }
}

function clearCredential() {
    let response = {
        address: '',
        admin: '',
        appId: '',
        cityId: '',
        cityName: '',
        createAt: '',
        customerId: '',
        customerName: '',
        customerProxyId: '',
        customerProxyName: '',
        deviceAlertCount: '',
        deviceCount: '',
        districtId: '',
        districtName: '',
        email: '',
        houseArea: '',
        id: '',
        image: '',
        isAdmin: false,
        isCustomer: false,
        isUser: false,
        locked: '',
        loginDate: '',
        loginIp: '',
        mac: '',
        mobile: '',
        name: '',
        openid: '',
        permissions: '',
        provinceId: '',
        provinceName: '',
        publicNumberId: '',
        remark: '',
        role: '',
        roleId: '',
        roleName: '',
        source: '',
        username: ''
    }
    save2local(response)
}

function float2percent(value) {
    var percent = Number(value * 100).toFixed(1);
    return percent;
}

function load_script(url, method) {
    let node = document.createElement('script');
    node.type = 'text/javascript';
    if (node.readyState) { // IE
        node.onreadystatechange = function () {
            if (node.readyState == 'loaded' || node.readyState === 'complete') {
                node.onreadystatechange = null;
                method();
            }
        };
    } else { // Others
        node.onload = method;
    }
    node.src = url;
    document.getElementsByTagName('head')[0].appendChild(node);
}

function tell_pm2_5_color(value) {
    if (value >= 0 && value <= 35) {
        return '#00CC66';
    }
    if (value > 35 && value <= 75) {
        return '#CC9900';
    }
    if (value > 75 && value <= 115) {
        return '#FF9966';
    }
    if (value > 115 && value <= 150) {
        return '#FF0066';
    }
    if (value > 150 && value <= 250) {
        return '#990099';
    }
    return '#996666';
}

function randomInData(min, max, length) {
    let result = []
    for (let i = 1; i <= length; i++) {
        result.push(Math.floor(Math.random() * (max - min)) + min)
    }
    return result;
}

//自动补0
function PrefixInteger(num, n) {
    return (Array(n).join(0) + num).slice(-n);
}


function interpret_bactericidal(json) {
    let count = 0;
    let detail = [];
    if (json.time1 !== null) {
        count++;
        detail[0] = json.time1;
    }
    if (json.time2 !== null) {
        count++;
        detail[1] = json.time2;
    }
    if (json.time3 !== null) {
        count++;
        detail[2] = json.time3;
    }
    if (json.time4 !== null) {
        count++;
        detail[3] = json.time4;
    }
    if (json.time5 !== null) {
        count++;
        detail[4] = json.time5;
    }
    if (json.time6 !== null) {
        count++;
        detail[5] = json.time6;
    }
    if (json.time7 !== null) {
        count++;
        detail[6] = json.time7;
    }
    if (json.time8 !== null) {
        count++;
        detail[7] = json.time8;
    }
    if (json.time9 !== null) {
        count++;
        detail[8] = json.time9;
    }
    if (json.time10 !== null) {
        count++;
        detail[9] = json.time10;
    }
    return {count: count, detail: detail}
}

function format(value) {
    if (value.length > 8) {
        return value.substring(0, 8) + "..."
    } else {
        return value;
    }
}

function to_binary(n) {
    if (n == 0) return '0';
    let res = '';
    while (n > 0) {
        if (n % 2 == 0) {
            res = '0' + res
        } else {
            res = '1' + res
        }
        n = parseInt(n / 2)
    }
    let length = 8 - res.length;
    for (let i = 0; i < length; i++) {
        res = '0' + res;
    }
    return res;
}


function format_time(hour, minute) {
    let result = "";
    if (hour < 10) {
        result = "0" + hour.toString();
    } else {
        result = "" + hour.toString();
    }
    result += ":";
    if (minute < 10) {
        result += "0" + minute.toString();
    } else {
        result += minute.toString()
    }
    return result;
}

export const util_service = {
    login,
    obtain_captcha,
    save2local,
    loadLocal,
    loadCredential,
    clearCredential,
    float2percent,
    format,
    overview,
    load_script,
    tell_pm2_5_color,
    randomInData,
    PrefixInteger,
    interpret_bactericidal,
    to_binary,
    format_time
}
