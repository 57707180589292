import React, {Component} from 'react';
import '../../scss/userCustom.scss';
import {Col, Container, Row} from "react-bootstrap";
import {TableCell, TableBody, TableRow, TableHead, Table} from '@material-ui/core'
import ReactEcharts from "echarts-for-react";
import ShowItem from "./showItem";

import {connect} from 'react-redux';
import {machine_service} from "../../service/machine";

import {updateOnlineRate, obtainCurrentAirQuality,obtainDevice} from "../../action";
import {airquality_service} from "../../service/airquality";

function mapStateToProps(state) {
    return {
        onlineRate: state.machineReducer.onlineRate,
        cityid: state.locationReducer.city_id,
        pm2_5: state.airReducer.pm2_5,
        aqi: state.airReducer.aqi,
        aqiLevel: state.airReducer.aqiLevel,
        pm10: state.airReducer.pm10,
        devices: state.machineReducer.devices
    }
}

const mapDispatchToProps = {
    updateOnlineRate, obtainCurrentAirQuality,obtainDevice
}

class UserShowComponent extends Component {

    constructor() {
        super();
        this.refresh.bind(this);
    }

    componentDidMount() {
        machine_service.obtainDevice().then(response => {
            response = response.data;
            this.props.obtainDevice({devices: response.datas})
        })

        setInterval(function () {
            this.refresh();
        }.bind(this), 10000);
        if (this.props.cityid !== undefined && this.props.cityid !== "") {
            airquality_service.obtainGMOutPM25ById(this.props.cityid).then(response => {
                // response = response.data;
                // if (response === null) {
                //     console.log("当前城市无PM2.5的数据")
                // } else {
                //
                // }
                response = response.data[0];
                this.props.obtainCurrentAirQuality({
                    aqi: response.aqi,
                    aqiLevel: response.aqiLevel,
                    co: response.co,
                    no2: response.no2,
                    o3: response.o3,
                    pm2_5: response.pm2_5,
                    pm10: response.pm10,
                    so2: response.so2
                })

            })
        }
    }

    send_message(topic, message) {
        const {mqtt} = this.props;
        mqtt.publish(topic, message)
    }

    report() {
        let devices = this.props.devices;
        console.log(JSON.stringify(devices))
    }


    refresh = () => {
        machine_service.obtainOnlineRate().then(response => {
            this.props.updateOnlineRate({onlineRate: response})
        });
    }

    // createData(name, calories, fat, carbs, protein, attr1, attr2, attr3) {
    //     return {name, calories, fat, carbs, protein, attr1, attr2, attr3};
    // }


    render() {
        console.log(this.props)


        const option = {
            tooltip: {
                formatter: "{a} <br/>{b} : {c}%"
            },
            series: [
                {
                    name: '设备在线率',
                    type: 'gauge',
                    detail: {formatter:  '{value}%'},
                    data: [{value: this.props.onlineRate, name: '在线率'}]
                }
            ]
        };
        return (
            <div className="user-dashboard-show-panel">
                <div className='show-box-panel'>
                    <Row>
                        <Col sm='8'>
                            <Container className='complete-ratio'>
                                <div className='topic'>设备在线率</div>
                                <ReactEcharts option={option} theme='light'/>
                            </Container>
                        </Col>
                        <Col sm='4'>
                            <Container className='compare-box warning'>
                                <div className="compare-box-item">消毒前空气质量</div>
                                <div className="compare-box-item"><span
                                    className="compare-box-item-value">317</span><span
                                    className="compare-box-item-unit">&nbsp;&nbsp;μg/m³</span></div>
                                <div className="compare-box-item">重度污染</div>
                            </Container>
                            <Container className='compare-box normal'>
                                <div className="compare-box-item">消毒后空气质量</div>
                                <div className="compare-box-item"><span
                                    className="compare-box-item-value">000</span><span
                                    className="compare-box-item-unit">&nbsp;&nbsp;μg/m³</span></div>
                                <div className="compare-box-item">优</div>
                            </Container>
                        </Col>
                    </Row>
                </div>
                <div className="show-item-panel">
                    <div className="show-item"><ShowItem icon={"cil-grain"} title={"PM2.5"} value={133} unit={"μg/m³"}/>
                    </div>
                    <div className="show-item"><ShowItem icon={"cil-room"} title={"甲醛"} value={8} unit={"mg/100g"}/>
                    </div>
                    <div className="show-item"><ShowItem icon={"cil-paint-bucket"} title={"TOVC"} value={1.0}
                                                         unit={"mg/m³"}/></div>
                </div>
                <div className="show-item-panel">
                    <div className="show-item"><ShowItem icon={"cil-sun"} title={"温度"} value={26} unit={"℃"}/></div>
                    <div className="show-item"><ShowItem iconName={"CO₂"} title={"二氧化碳"} value={36} unit={"mol/L"}/>
                    </div>
                    <div className="show-item"><ShowItem icon={"cil-drop"} title={"湿度"} value={133} unit={"μg/m³"}/>
                    </div>
                </div>
                <div className="show-table-panel">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>设备名称</TableCell>
                                <TableCell>所属客户</TableCell>
                                <TableCell>所属部门</TableCell>
                                <TableCell>产品型号</TableCell>
                                <TableCell>运行状态</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.devices.map((row) => (
                                <TableRow key={row.name} onClick={() => {
                                    window.location = "/#/userDashboard/device/" + row.id
                                }}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>{row.customerName}</TableCell>
                                    <TableCell>{row.customerProxyName}</TableCell>
                                    <TableCell>{row.productTypeCode}</TableCell>
                                    <TableCell>{row.isOnline?'是':'否'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(UserShowComponent);
