import React, {Component} from 'react';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from 'reactstrap';
import {connect} from "react-redux";

import 'bootstrap/dist/css/bootstrap.min.css';

import {util_service} from "../../../service/util";

import {
    fillCode,
    fillPassword,
    fillUsername,
    genCaptcha,
    hideAlert,
    showAlert,
    genAlertMsg,
    saveCredential
} from "../../../action/index";
import {user_service} from "../../../service/user";

//这个函数提取并返回当前组建需要的属性
function mapStateToProps(state) {
    return {
        username: state.credentialReducer.username,
        password: state.credentialReducer.password,
        captcha: state.credentialReducer.captcha,
        code: state.credentialReducer.code,
        alert_visible: state.credentialReducer.alert_visible,
        alert_msg: state.credentialReducer.alert_msg
    }
}

const mapDispatchToProps = {
    fillCode,
    fillPassword,
    fillUsername,
    genCaptcha,
    hideAlert,
    showAlert,
    genAlertMsg,
    saveCredential
}

class LoginComponent extends Component {
    constructor() {
        super();
        this.dismiss = this.dismiss.bind(this);
        this.login = this.login.bind(this);
        this.refresh_captcha = this.refresh_captcha.bind(this);
        this.read_username = this.read_username.bind(this);
        this.read_password = this.read_password.bind(this);
        this.read_code = this.read_code.bind(this);
    }

    componentDidMount() {
        util_service.obtain_captcha().then(response => {
            let image = response.data.imagedata;
            this.props.genCaptcha({captcha: image})
        });
        // util_service.load_script("https://reception.gmair.net/plugin/vconsole.min.js", () => {
        //     var vConsole = new window.VConsole();
        // })
    }

    refresh_captcha = () => {
        util_service.obtain_captcha().then(response => {
            let image = response.data.imagedata;
            this.props.genCaptcha({captcha: image})
        });
    }

    read_username = (e) => {
        let username = e.target.value;
        this.props.fillUsername({username: username});
    }

    read_password = (e) => {
        let password = e.target.value;
        this.props.fillPassword({password: password});
    }

    read_code = (e) => {
        let code = e.target.value;
        this.props.fillCode({code: code});
    }

    login = () => {
        let username = this.props.username, password = this.props.password, code = this.props.code;
        util_service.login(username, password, code).then(response => {
            if (response.errorCode === 0) {
                response = response.data.user;
                console.log(JSON.stringify(response))
                let userInfo = {
                    authenticated: true,
                    address: response.address,
                    admin: response.admin,
                    appId: response.appId,
                    cityId: response.cityId,
                    cityName: response.cityName,
                    createAt: response.createTime,
                    customerId: response.customerId,
                    customerName: response.customerName,
                    customerProxyId: response.customerProxyId,
                    customerProxyName: response.customerProxyName,
                    deviceAlertCount: response.deviceAlertCount,
                    deviceCount: response.deviceCount,
                    districtId: response.districtId,
                    districtName: response.districtName,
                    email: response.email,
                    houseArea: response.houseArea,
                    id: response.id,
                    image: response.image,
                    isAdmin: response.isAdmin,
                    isCustomer: response.isCustomer,
                    isUser: response.isUser,
                    locked: response.locked,
                    loginDate: response.loginDate,
                    loginIp: response.loginIp,
                    mac: response.mac,
                    mobile: response.mobile,
                    name: response.name,
                    openid: response.openid,
                    permissions: response.permissions,
                    provinceId: response.provinceId,
                    provinceName: response.provinceName,
                    publicNumberId: response.publicNumberId,
                    remark: response.remark,
                    role: response.role,
                    roleId: response.roleId,
                    roleName: response.roleName,
                    source: response.source,
                    username: response.username
                }
                this.props.saveCredential(userInfo);
                util_service.save2local(response)
                // this.props.history.push('/userDashboard');
                this.props.history.push('/dashboard');
            } else {
                let msg = response.errorMessage;
                this.props.showAlert({alert_visible: false});
                this.props.genAlertMsg({alert_msg: msg});
                this.props.fillCode({code: ''});
                this.refresh_captcha()
            }
        });
    }

    dismiss() {
        this.props.showAlert({alert_visible: false});
    }

    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <div>
                                            <h1>登录</h1>
                                            <p className="text-muted">登录您的账号以进行操作</p>
                                            <Alert color="danger" isOpen={this.props.alert_visible}
                                                   toggle={this.dismiss}>{this.props.alert_msg}
                                            </Alert>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="cil-user"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" placeholder="用户名" autoComplete="off"
                                                       value={this.props.username} onChange={this.read_username}/>
                                            </InputGroup>
                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="cil-lock-locked"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password" placeholder="密码" autoComplete="off"
                                                       value={this.props.password} onChange={this.read_password}/>
                                            </InputGroup>
                                            <InputGroup className="mb-4">
                                                <Input type="text" placeholder="验证码" autoComplete="off"
                                                       value={this.props.code} onChange={this.read_code}/>
                                                <img src={this.props.captcha} height='35px'></img>
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText onClick={this.refresh_captcha}>
                                                        <i className="cil-sync"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <Row>
                                                <Col xs="6">
                                                    <Button color="primary" className="px-4"
                                                            onClick={this.login}>登录</Button>
                                                </Col>
                                                <Col xs="6" className="text-right">
                                                    <Button color="link" className="px-0">忘记密码</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

export default Login;
