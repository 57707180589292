import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem,InputGroup,InputGroupAddon,Button,Input} from "reactstrap";
import {Link} from 'react-router-dom'
import '../../scss/client.scss';
import { render } from "react-dom";

import {connect} from 'react-redux';

// Import React Table
import ReactTable from "react-table";
import {machine_service} from "../../service/machine";
import {datetimeService} from "../../service/datetime";

function mapStateToProps(state) {
    return {

    }
}

class SysLogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{
                Header : '设备MAC',
                accessor : 'mac'
            },  {
                Header : '设备名称',
                accessor : 'deviceName'
            },{
                Header : '操作人员',
                accessor : 'name'
            }, {
                Header : '操作详情',
                accessor : 'operations',
            }, {
                Header : '操作时间',
                accessor : 'createTime',
                Cell:row=>(<div>
                    {datetimeService.formatTimeStampToDateTime(row.original.createTime)}
                </div>)
            }],
            dataList: [{
                mac: "98D3232D63",
                deviceName: "消毒剂-001型",
                name: "龙的传人",
                operations: "运行设备",
                createTime: 1576628831532,
            }]
        }
    }

    componentDidMount() {
        machine_service.obtainDevice().then(response => {
            // console.log(response)
        })
    }

    render() {
        let {columns, dataList} = this.state;
        return (
            <div className="client-panel">
                <div className="client-top">
                    <div className="client-bread">
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/#/dashboard">首页</a></BreadcrumbItem>
                            <BreadcrumbItem><a href="/#/device">设备管理</a></BreadcrumbItem>
                            <BreadcrumbItem active>系统日志</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="client-search">
                        <InputGroup className="client-search-input">
                            <Input placeholder="请输入搜索内容"/>
                            <InputGroupAddon addonType="append">
                                <Button color="secondary">搜索</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
                <div className="table-panel">
                    <ReactTable
                        data={dataList}
                        columns={columns}
                        defaultPageSize={15}
                        className="-highlight table-row"
                        pageSizeOptions={[15]}
                    />
                </div>
            </div>
        );
    }

}

const SysLog = connect()(SysLogComponent);

export default SysLog;

