import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem, Button, Input, InputGroup, InputGroupAddon} from "reactstrap";
import '../../scss/client.scss';
// Import React Table
import ReactTable from "react-table";

import {connect} from 'react-redux'

import {alarm_service} from "../../service/alarm";

import {obtainDeviceAlarms} from "../../action";
import {datetimeService} from "../../service/datetime";
import {customer_service} from "../../service/customer";

function mapStateToProps(state) {
    return {
        dataList: state.alarmReducer.dataList,
        totalCount: state.alarmReducer.totalCount,

    }
}

const mapDispatchToProps = {
    obtainDeviceAlarms
}

class AlarmComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            search:null,
            isProxy:null,
            // page:0,
            pageSize:10,
            pageSizeList:[10,15,20,50]
        }
    }

    obtainAlarm(pageSize){
        alarm_service.obtainDeviceAlarms(this.state.search,0,pageSize).then(response => {
            let data = response.data;
            console.log(data)
            this.props.obtainDeviceAlarms({
                size: data.size,
                page: data.page,
                upPage: data.upPage,
                nextPage: data.nextPage,
                totalCount: data.totalCount,
                totalPage: data.totalPage,
                firstResult: data.firstResult,
                lastResult: data.lastResult,
                dataList: data.datas
            })
        })
    }

    componentDidMount() {
        alarm_service.obtainDeviceAlarms(this.state.search,0,this.state.pageSize).then(response => {
            let data = response.data;
            this.props.obtainDeviceAlarms({
                size: data.size,
                page: data.page,
                upPage: data.upPage,
                nextPage: data.nextPage,
                totalCount: data.totalCount,
                totalPage: data.totalPage,
                firstResult: data.firstResult,
                lastResult: data.lastResult,
                dataList: data.datas
            })
            this.obtainAlarm(data.totalCount)
        })
    }

    render() {
        const columns = [{
            Header: '设备MAC',
            accessor: 'mac'
        }, {
            Header: '设备',
            accessor: 'deviceTypeName'
        },{
            Header: '报警信息',
            accessor: 'alertName'
        }, {
            Header: '所属客户',
            accessor: 'customerName',
        }, {
            Header: '所属科室',
            accessor: 'customerProxyName',
        },
            {
            Header: '报警时间',
            accessor: 'updateTime',
            Cell: row => (<div>
                {datetimeService.formatTimeStampToDateTime(row.original.updateTime)}
            </div>)
        }, {
            Header: '操作',
            accessor: 'action',
            Cell: row => (<div>
                <a style={{color: 'blue'}}>确认已处理</a>
            </div>)
        }];

        return (
            <div className="client-panel">
                <div className="client-top">
                    <div className="client-bread">
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/#/dashboard">首页</a></BreadcrumbItem>
                            <BreadcrumbItem active>报警信息</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div className="client-search">
                        <InputGroup className="client-search-input">
                            <Input placeholder="请输入搜索内容" onChange={(e)=>{
                                this.setState({
                                    search:e.target.value
                                })
                            }}/>
                            <InputGroupAddon addonType="append">
                                <Button color="secondary" onClick={()=>{
                                    this.obtainAlarm(this.props.totalCount)
                                }}>搜索</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
                <div className="table-panel">
                    <ReactTable
                        data={this.props.dataList}
                        columns={columns}
                        defaultPageSize={this.state.pageSize}
                        className="-highlight table-row"
                        pageSizeOptions={this.state.pageSizeList}
                        previousText={"上一页"}
                        nextText={"下一页"}
                        noDataText={"没有数据"}
                        pageText={"当前页"}
                        ofText={"总页数"}
                        rowsText={"条/页"}
                    />
                </div>
            </div>
        );
    }

}

const Alarm = connect(mapStateToProps, mapDispatchToProps)(AlarmComponent)

export default Alarm;

