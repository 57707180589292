import React from 'react'
import {Card} from 'react-bootstrap'
import {connect} from 'react-redux'
import {Timeline, TimelineEvent} from "react-event-timeline";

import {obtainSysLog} from "../../action";
import {log_service} from "../../service/log";
import {datetimeService} from "../../service/datetime";

function mapStateToProps(state) {
    return {log_list: state.logReducer.logs}
}

const mapDispatchToProps = {
    obtainSysLog
}


class SystemLogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.obtain_system_log.bind(this);
    }

    componentDidMount() {
        if (this.props.mac !== undefined && this.props.mac !== "") {
            this.obtain_system_log(this.props.mac)
        }
    }

    componentWillReceiveProps(next) {
        if (next.mac !== "" && next.mac != this.props.mac) {
            this.obtain_system_log(next.mac)
        }
    }

    obtain_system_log(mac) {
        log_service.obtainSysLogByMac(mac).then(response => {
            response = response.data;
            if (response.data === null) return;
            this.props.obtainSysLog({
                size: response.size,
                page: response.page,
                upPage: response.upPage,
                nextPage: response.nextPage,
                totalCount: response.totalCount,
                totalPage: response.totalPage,
                firstResult: response.firstResult,
                lastResult: response.lastResult,
                logs: response.datas
            })
        })
    }

    render() {
        return (
            <Card className="log-card">
                <Card.Header>操作日志</Card.Header>
                <Card.Body className="log-card-body">
                    <Timeline>
                        {this.props.log_list.map((item, index) => {
                            return (<TimelineEvent key={item.id}
                                                   createdAt={datetimeService.formatTimeStampToDateTime(item.createTime)}
                            >
                                {item.name}{item.operation}{item.deviceName}
                            </TimelineEvent>)
                        })}
                    </Timeline>
                </Card.Body>
            </Card>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SystemLogComponent)

