import React, {Component} from 'react';

import {Card, Col, Container, Row} from "react-bootstrap";
import { Switch, Slider} from '@material-ui/core'
import ShowItem from "../Show/showItem";
import '../../scss/userCustom.scss';
import 'moment/locale/th';
import {Connector, subscribe} from 'react-mqtt';

import {machine_service} from "../../service/machine";
import _SurplusInfo from "../../views/Device/SurplusInfo";
import _RunningStatus from "../../views/Device/RunningStatus";
import _TimingMode from "../../views/Device/TimingMode";
import _ControlMode from "../../views/Device/ControlMode";

class UserDeviceDetail extends Component {
    constructor() {
        super();
        this.state = {
            deviceName: '',
            mac: '',
            company: '',
            deviceClass: '',
            deviceCode: '',
            isOnline: false,
            location: '',
            softVersion: '',
            firmVersion: '',
            latestVersion: '',
            deviceImage: '',
            deviceQrcode: '',
            log_list: [1, 2, 3, 4],
        }

    }

    componentDidMount() {
        let id = this.props.match.params.deviceId;
        machine_service.profile(id).then(response => {
            response = response.data;
            let deviceName = response.name;
            let mac = response.mac;
            let company = response.customerName;
            let department = response.customerProxyName;
            let deviceClass = response.deviceTypeBean.name;
            let deviceCode = response.deviceTypeBean.productTypeCode;
            let online = response.online;
            let location = response.provinceName + response.cityName;
            let softVersion = response.swVersion;
            let firmVersion = response.hwVersion;
            let latestVersion = response.existNewVersion;
            let deviceImage = response.deviceTypeBean.images;
            let deviceQrcode = response.deviceTypeBean.qrticket;
            this.setState({
                deviceName: deviceName,
                mac: mac,
                company: company,
                isOnline: online,
                department: department,
                deviceClass: deviceClass,
                deviceCode: deviceCode,
                location: location,
                softVersion: softVersion,
                firmVersion: firmVersion,
                latestVersion: latestVersion,
                deviceImage: deviceImage,
                deviceQrcode: deviceQrcode
            })
        })
    }

    // createData(name, calories, fat, carbs, protein,attr1,attr2,attr3) {
    //     return { name, calories, fat, carbs, protein,attr1,attr2,attr3};
    // }

    render() {
        let mac = this.state.mac;
        const RunningStatus = subscribe({topic: '/XD/+/' + mac + '/+/+/sys_status'})(_RunningStatus)
        const TimingMode = subscribe({topic: '/XD/+/' + mac + '/+/+/timer'})(_TimingMode)
        const ControlMode = subscribe({topic: '/XD/+/' + mac + '/+/+/timeseg'})(_ControlMode)

        const marks = [
            {
                value: 0,
                label: '0',
            },
            {
                value: 1,
                label: '1',
            },
            {
                value: 2,
                label: '2',
            },
            {
                value: 3,
                label: '3',
            },
        ];
        return (
            <Connector mqttProps="ws://finley:finley@47.103.193.179:8083/mqtt">
                <div className="user-dashboard-show-panel">
                    <div className='show-box-panel'>
                        <div className="user-chart-panel">
                            <Card className="detail-card over-card">
                                <Card.Header>定时设定</Card.Header>
                                <Card.Body>
                                    <TimingMode mac={mac}/>
                                    <ControlMode mac={mac}/>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <div className="show-item-panel">
                        <div className="show-item"><ShowItem icon={"cil-grain"} title={"PM2.5"} value={133}
                                                             unit={"μg/m³"}/></div>
                        <div className="show-item"><ShowItem icon={"cil-room"} title={"甲醛"} value={8} unit={"mg/100g"}/>
                        </div>
                        <div className="show-item"><ShowItem icon={"cil-paint-bucket"} title={"TOVC"} value={1.0}
                                                             unit={"mg/m³"}/></div>
                    </div>
                    <div className="show-item-panel">
                        <div className="show-item"><ShowItem icon={"cil-sun"} title={"温度"} value={26} unit={"℃"}/></div>
                        <div className="show-item"><ShowItem iconName={"CO₂"} title={"二氧化碳"} value={36} unit={"mol/L"}/>
                        </div>
                        <div className="show-item"><ShowItem icon={"cil-drop"} title={"湿度"} value={133} unit={"μg/m³"}/>
                        </div>
                    </div>
                    <div className="show-table-panel">
                        {/*<Row>*/}
                        {/*<Col sm='12'>*/}
                        <div className='detail-item'>
                            <RunningStatus mac={mac}/>
                        </div>
                        {/*</Col>*/}
                        {/*</Row>*/}
                    </div>
                </div>
            </Connector>


        );
    }

}

export default UserDeviceDetail;

