import React from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {connect} from 'react-redux';
import {machine_service} from "../../service/machine";

import {obtainUserList4Device} from "../../action"

function mapStateToProps(state) {
    return {
        user_list: state.statusReducer.user_list
    }
}

const mapDispatchToProps = {
    obtainUserList4Device
}

class DeviceUserComponent extends React.Component {

    constructor(props) {
        super(props);
        this.obtain_device_users.bind(this);
    }

    componentDidMount() {
        if (this.props.mac !== undefined && this.props.mac !== "") {
            this.obtain_device_users(this.props.mac)
        }
    }

    componentWillReceiveProps(next) {
        if (next.mac !== "" && next.mac != this.props.mac) {
            this.obtain_device_users(next.mac)
        }
    }

    obtain_device_users(mac) {
        machine_service.obtainDeviceUsers(mac).then(response => {
            response = response.data;
            response = response.datas;
            this.props.obtainUserList4Device({user_list: response})
        })
    }

    render() {
        return (
            <Card className="detail-card over-card">
                <Card.Header>设备关联用户</Card.Header>
                <Card.Body>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>头像</TableCell>
                                <TableCell>姓名</TableCell>
                                <TableCell>管理员</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.user_list.length !== 0 && this.props.user_list.map((row, index) => (
                                <TableRow key={"user" + index + row.name}>
                                    <TableCell component="th" scope="row">
                                        <img style={{width: `32px`, height: `32px`}} src={row.userImage}/>
                                    </TableCell>
                                    <TableCell>{row.userName}</TableCell>
                                    {row.isAdmin == true && <TableCell>管理员</TableCell>}
                                    {row.isUser == true && <TableCell>用户</TableCell>}
                                    {row.isCustomer == true && <TableCell>客户</TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceUserComponent)
