import {OBTAIN_CURRENT_AIRQUALITY} from "../constant/action-type";

const initialState = {
    aqi: 0,
    aqiLevel: '优',
    co: 0,
    no2: 0,
    o3: 0,
    pm2_5: 0,
    pm10: 0,
    so2: 0,
}

function airReducer(state = initialState, action) {
    if (action.type === OBTAIN_CURRENT_AIRQUALITY) {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default airReducer;