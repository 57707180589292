import {OBTAIN_CUSTOMER} from "../constant/action-type";

const initialState = {
    size: 0,
    page : 0,
    upPage : 0,
    nextPage : 1,
    totalCount : 12,
    totalPage : 1,
    firstResult : 0,
    lastResult : 15,
    customers: [{
        name: "张一山",
        phone: "182****9285",
        address: "江苏省无锡市****",
        proxy: "是",
        proxyName: "区域代理",
    }, {
        name: "王威",
        phone: "138****9235",
        address: "江苏省南京市****",
        proxy: "否",
        // proxyName: "区域代理",
    }
    ]
}

function customerReducer(state = initialState, action) {
    if (action.type == OBTAIN_CUSTOMER) {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default customerReducer;